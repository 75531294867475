.play-swiper .swiper-slide {
  position: relative;
}

.character-card {
  box-sizing: border-box;
  position: relative;
  width: 21.19791vw;
  height: calc(21.19791vw * calc(532 / 407));
  transition: all 0.3s;
  font-family: "Arial Black", "Arial Bold";
  margin: 0 auto;

  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 999;
  cursor: pointer;
  transform: translate(-50%, -50%) scale(2.7);
}

.selected-card {
  width: 47.7604vw;
  height: calc(47.7604vw * calc(500 / 917));
  padding: 7px;
}

.character-card > img {
  width: 21.3vw;
  height: auto;
}

.character-card-SSSR-bg {
  background: radial-gradient(at 50% 25%, #f9df51, #d13700);
  width: 85%;
  height: 79%;
  border-radius: 1.041vw;
  border: 0.3125vw solid #ffc600;
  box-sizing: border-box;

  position: absolute;
  left: 50%;
  bottom: 9.7%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  z-index: -1;
  transition: all 0.3s;

  box-shadow: 0 8px 8px rgb(0 0 0 / 50%);
}

.character-card-UR-bg {
  width: 100%;
  height: 100%;
  /* background-image: url(../images/link/rolecard/UR-bg.png); */
  background-size: contain;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  transition: all 0.3s;
}

.img-UR-bg {
  position: absolute;
  z-index: -99;
}

.selected-card .character-card-SSSR-bg,
.selected-UR-card-bg {
  bottom: 0%;
  width: 100%;
  height: 100%;
  border-radius: 1.82291666vw;
}

@keyframes scanMove {
  0% {
    mask-image: linear-gradient(
      60deg,
      transparent -40%,
      black -30%,
      transparent 3%
    );
  }
  1% {
    mask-image: linear-gradient(
      60deg,
      transparent -39%,
      black -29%,
      transparent 4%
    );
  }
  2% {
    mask-image: linear-gradient(
      60deg,
      transparent -38%,
      black -28%,
      transparent 5%
    );
  }
  3% {
    mask-image: linear-gradient(
      60deg,
      transparent -37%,
      black -27%,
      transparent 6%
    );
  }
  4% {
    mask-image: linear-gradient(
      60deg,
      transparent -36%,
      black -26%,
      transparent 7%
    );
  }
  5% {
    mask-image: linear-gradient(
      60deg,
      transparent -35%,
      black -25%,
      transparent 8%
    );
  }
  6% {
    mask-image: linear-gradient(
      60deg,
      transparent -34%,
      black -24%,
      transparent 9%
    );
  }
  7% {
    mask-image: linear-gradient(
      60deg,
      transparent -33%,
      black -23%,
      transparent 10%
    );
  }
  8% {
    mask-image: linear-gradient(
      60deg,
      transparent -32%,
      black -22%,
      transparent 11%
    );
  }
  9% {
    mask-image: linear-gradient(
      60deg,
      transparent -31%,
      black -21%,
      transparent 12%
    );
  }
  10% {
    mask-image: linear-gradient(
      60deg,
      transparent -30%,
      black -20%,
      transparent 13%
    );
  }
  11% {
    mask-image: linear-gradient(
      60deg,
      transparent -29%,
      black -19%,
      transparent 14%
    );
  }
  12% {
    mask-image: linear-gradient(
      60deg,
      transparent -28%,
      black -18%,
      transparent 15%
    );
  }
  13% {
    mask-image: linear-gradient(
      60deg,
      transparent -27%,
      black -17%,
      transparent 16%
    );
  }
  14% {
    mask-image: linear-gradient(
      60deg,
      transparent -26%,
      black -16%,
      transparent 17%
    );
  }
  15% {
    mask-image: linear-gradient(
      60deg,
      transparent -25%,
      black -15%,
      transparent 18%
    );
  }
  16% {
    mask-image: linear-gradient(
      60deg,
      transparent -24%,
      black -14%,
      transparent 19%
    );
  }
  17% {
    mask-image: linear-gradient(
      60deg,
      transparent -23%,
      black -13%,
      transparent 20%
    );
  }
  18% {
    mask-image: linear-gradient(
      60deg,
      transparent -22%,
      black -12%,
      transparent 21%
    );
  }
  19% {
    mask-image: linear-gradient(
      60deg,
      transparent -21%,
      black -11%,
      transparent 22%
    );
  }
  20% {
    mask-image: linear-gradient(
      60deg,
      transparent -20%,
      black -10%,
      transparent 23%
    );
  }
  21% {
    mask-image: linear-gradient(
      60deg,
      transparent -19%,
      black -9%,
      transparent 24%
    );
  }
  22% {
    mask-image: linear-gradient(
      60deg,
      transparent -18%,
      black -8%,
      transparent 25%
    );
  }
  23% {
    mask-image: linear-gradient(
      60deg,
      transparent -17%,
      black -7%,
      transparent 26%
    );
  }
  24% {
    mask-image: linear-gradient(
      60deg,
      transparent -16%,
      black -6%,
      transparent 27%
    );
  }
  25% {
    mask-image: linear-gradient(
      60deg,
      transparent -15%,
      black -5%,
      transparent 28%
    );
  }
  26% {
    mask-image: linear-gradient(
      60deg,
      transparent -14%,
      black -4%,
      transparent 29%
    );
  }
  27% {
    mask-image: linear-gradient(
      60deg,
      transparent -13%,
      black -3%,
      transparent 30%
    );
  }
  28% {
    mask-image: linear-gradient(
      60deg,
      transparent -12%,
      black -2%,
      transparent 31%
    );
  }
  29% {
    mask-image: linear-gradient(
      60deg,
      transparent -11%,
      black -1%,
      transparent 32%
    );
  }
  30% {
    mask-image: linear-gradient(
      60deg,
      transparent -10%,
      black 0%,
      transparent 33%
    );
  }
  31% {
    mask-image: linear-gradient(
      60deg,
      transparent -9%,
      black 1%,
      transparent 34%
    );
  }
  32% {
    mask-image: linear-gradient(
      60deg,
      transparent -8%,
      black 2%,
      transparent 35%
    );
  }
  33% {
    mask-image: linear-gradient(
      60deg,
      transparent -7%,
      black 3%,
      transparent 36%
    );
  }
  34% {
    mask-image: linear-gradient(
      60deg,
      transparent -6%,
      black 4%,
      transparent 37%
    );
  }
  35% {
    mask-image: linear-gradient(
      60deg,
      transparent -5%,
      black 5%,
      transparent 38%
    );
  }
  36% {
    mask-image: linear-gradient(
      60deg,
      transparent -4%,
      black 6%,
      transparent 39%
    );
  }
  37% {
    mask-image: linear-gradient(
      60deg,
      transparent -3%,
      black 7%,
      transparent 40%
    );
  }
  38% {
    mask-image: linear-gradient(
      60deg,
      transparent -2%,
      black 8%,
      transparent 41%
    );
  }
  39% {
    mask-image: linear-gradient(
      60deg,
      transparent -1%,
      black 9%,
      transparent 42%
    );
  }
  40% {
    mask-image: linear-gradient(
      60deg,
      transparent 0%,
      black 10%,
      transparent 43%
    );
  }
  41% {
    mask-image: linear-gradient(
      60deg,
      transparent 1%,
      black 11%,
      transparent 44%
    );
  }
  42% {
    mask-image: linear-gradient(
      60deg,
      transparent 2%,
      black 12%,
      transparent 45%
    );
  }
  43% {
    mask-image: linear-gradient(
      60deg,
      transparent 3%,
      black 13%,
      transparent 46%
    );
  }
  44% {
    mask-image: linear-gradient(
      60deg,
      transparent 4%,
      black 14%,
      transparent 47%
    );
  }
  45% {
    mask-image: linear-gradient(
      60deg,
      transparent 5%,
      black 15%,
      transparent 48%
    );
  }
  46% {
    mask-image: linear-gradient(
      60deg,
      transparent 6%,
      black 16%,
      transparent 49%
    );
  }
  47% {
    mask-image: linear-gradient(
      60deg,
      transparent 7%,
      black 17%,
      transparent 50%
    );
  }
  48% {
    mask-image: linear-gradient(
      60deg,
      transparent 8%,
      black 18%,
      transparent 51%
    );
  }
  49% {
    mask-image: linear-gradient(
      60deg,
      transparent 9%,
      black 19%,
      transparent 52%
    );
  }
  50% {
    mask-image: linear-gradient(
      60deg,
      transparent 10%,
      black 20%,
      transparent 53%
    );
  }
  51% {
    mask-image: linear-gradient(
      60deg,
      transparent 11%,
      black 21%,
      transparent 54%
    );
  }
  52% {
    mask-image: linear-gradient(
      60deg,
      transparent 12%,
      black 22%,
      transparent 55%
    );
  }
  53% {
    mask-image: linear-gradient(
      60deg,
      transparent 13%,
      black 23%,
      transparent 56%
    );
  }
  54% {
    mask-image: linear-gradient(
      60deg,
      transparent 14%,
      black 24%,
      transparent 57%
    );
  }
  55% {
    mask-image: linear-gradient(
      60deg,
      transparent 15%,
      black 25%,
      transparent 58%
    );
  }
  56% {
    mask-image: linear-gradient(
      60deg,
      transparent 16%,
      black 26%,
      transparent 59%
    );
  }
  57% {
    mask-image: linear-gradient(
      60deg,
      transparent 17%,
      black 27%,
      transparent 60%
    );
  }
  58% {
    mask-image: linear-gradient(
      60deg,
      transparent 18%,
      black 28%,
      transparent 61%
    );
  }
  59% {
    mask-image: linear-gradient(
      60deg,
      transparent 19%,
      black 29%,
      transparent 62%
    );
  }
  60% {
    mask-image: linear-gradient(
      60deg,
      transparent 20%,
      black 30%,
      transparent 63%
    );
  }
  61% {
    mask-image: linear-gradient(
      60deg,
      transparent 21%,
      black 31%,
      transparent 64%
    );
  }
  62% {
    mask-image: linear-gradient(
      60deg,
      transparent 22%,
      black 32%,
      transparent 65%
    );
  }
  63% {
    mask-image: linear-gradient(
      60deg,
      transparent 23%,
      black 33%,
      transparent 66%
    );
  }
  64% {
    mask-image: linear-gradient(
      60deg,
      transparent 24%,
      black 34%,
      transparent 67%
    );
  }
  65% {
    mask-image: linear-gradient(
      60deg,
      transparent 25%,
      black 35%,
      transparent 68%
    );
  }
  66% {
    mask-image: linear-gradient(
      60deg,
      transparent 26%,
      black 36%,
      transparent 69%
    );
  }
  67% {
    mask-image: linear-gradient(
      60deg,
      transparent 27%,
      black 37%,
      transparent 70%
    );
  }
  68% {
    mask-image: linear-gradient(
      60deg,
      transparent 28%,
      black 38%,
      transparent 71%
    );
  }
  69% {
    mask-image: linear-gradient(
      60deg,
      transparent 29%,
      black 39%,
      transparent 72%
    );
  }
  70% {
    mask-image: linear-gradient(
      60deg,
      transparent 30%,
      black 40%,
      transparent 73%
    );
  }
  71% {
    mask-image: linear-gradient(
      60deg,
      transparent 31%,
      black 41%,
      transparent 74%
    );
  }
  72% {
    mask-image: linear-gradient(
      60deg,
      transparent 32%,
      black 42%,
      transparent 75%
    );
  }
  73% {
    mask-image: linear-gradient(
      60deg,
      transparent 33%,
      black 43%,
      transparent 76%
    );
  }
  74% {
    mask-image: linear-gradient(
      60deg,
      transparent 34%,
      black 44%,
      transparent 77%
    );
  }
  75% {
    mask-image: linear-gradient(
      60deg,
      transparent 35%,
      black 45%,
      transparent 78%
    );
  }
  76% {
    mask-image: linear-gradient(
      60deg,
      transparent 36%,
      black 46%,
      transparent 79%
    );
  }
  77% {
    mask-image: linear-gradient(
      60deg,
      transparent 37%,
      black 47%,
      transparent 80%
    );
  }
  78% {
    mask-image: linear-gradient(
      60deg,
      transparent 38%,
      black 48%,
      transparent 81%
    );
  }
  79% {
    mask-image: linear-gradient(
      60deg,
      transparent 39%,
      black 49%,
      transparent 82%
    );
  }
  80% {
    mask-image: linear-gradient(
      60deg,
      transparent 40%,
      black 50%,
      transparent 83%
    );
  }
  81% {
    mask-image: linear-gradient(
      60deg,
      transparent 41%,
      black 51%,
      transparent 84%
    );
  }
  82% {
    mask-image: linear-gradient(
      60deg,
      transparent 42%,
      black 52%,
      transparent 85%
    );
  }
  83% {
    mask-image: linear-gradient(
      60deg,
      transparent 43%,
      black 53%,
      transparent 86%
    );
  }
  84% {
    mask-image: linear-gradient(
      60deg,
      transparent 44%,
      black 54%,
      transparent 87%
    );
  }
  85% {
    mask-image: linear-gradient(
      60deg,
      transparent 45%,
      black 55%,
      transparent 88%
    );
  }
  86% {
    mask-image: linear-gradient(
      60deg,
      transparent 46%,
      black 56%,
      transparent 89%
    );
  }
  87% {
    mask-image: linear-gradient(
      60deg,
      transparent 47%,
      black 57%,
      transparent 90%
    );
  }
  88% {
    mask-image: linear-gradient(
      60deg,
      transparent 48%,
      black 58%,
      transparent 91%
    );
  }
  89% {
    mask-image: linear-gradient(
      60deg,
      transparent 49%,
      black 59%,
      transparent 92%
    );
  }
  90% {
    mask-image: linear-gradient(
      60deg,
      transparent 50%,
      black 60%,
      transparent 93%
    );
  }
  91% {
    mask-image: linear-gradient(
      60deg,
      transparent 51%,
      black 61%,
      transparent 94%
    );
  }
  92% {
    mask-image: linear-gradient(
      60deg,
      transparent 52%,
      black 62%,
      transparent 95%
    );
  }
  93% {
    mask-image: linear-gradient(
      60deg,
      transparent 53%,
      black 63%,
      transparent 96%
    );
  }
  94% {
    mask-image: linear-gradient(
      60deg,
      transparent 54%,
      black 64%,
      transparent 97%
    );
  }
  95% {
    mask-image: linear-gradient(
      60deg,
      transparent 55%,
      black 65%,
      transparent 98%
    );
  }
  96% {
    mask-image: linear-gradient(
      60deg,
      transparent 56%,
      black 66%,
      transparent 99%
    );
  }
  97% {
    mask-image: linear-gradient(
      60deg,
      transparent 57%,
      black 67%,
      transparent 100%
    );
  }
  98% {
    mask-image: linear-gradient(
      60deg,
      transparent 58%,
      black 68%,
      transparent 101%
    );
  }
  99% {
    mask-image: linear-gradient(
      60deg,
      transparent 59%,
      black 69%,
      transparent 102%
    );
  }
  100% {
    mask-image: linear-gradient(
      60deg,
      transparent 60%,
      black 70%,
      transparent 103%
    );
  }
}

.selected-SSSR-card-bg {
  background: radial-gradient(ellipse 35% 100% at 25% 50%, #f9df51, #f15900);
}

.selected-UR-card-bg {
  border: 0.3125vw solid transparent;
  box-sizing: border-box;
  background: radial-gradient(closest-corner circle at 25% 50%, #fc88a9, #e62d77, #ba1313) padding-box,
    linear-gradient(to top, #fc88a9, #ba1313) border-box;
  box-shadow: 0 8px 8px rgb(0 0 0 / 50%);
}

.card-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 0.3125vw solid transparent;
  background-image: url(/src/images/link/card-mask.png);
  background-clip: border-box;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  mask-image: linear-gradient(
    60deg,
    transparent 0%,
    rgb(0, 0, 0) 33%,
    transparent 66%
  );
  mask-size: 170% 100%;
  mask-repeat: no-repeat;
  animation: scanMove 2s linear infinite;

  transition: all .6s;
}

.character-card-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex: 1;
  padding: 0 0.3125vw;
  transition: all .6s;
}

.hidden {
  display: none;
}

.character-shadow {
  width: 27.7%;
  height: 7.3%;
  background: rgba(0, 0, 0, 0.2);
  -moz-border-radius: 50% / 50%;
  -webkit-border-radius: 50% / 50%;
  border-radius: 50% / 50%;
  position: absolute;
  bottom: 15%;
  left: 14%;
  z-index: -1;
}

.character-shadow::after {
  content: "";
  width: 118.1%;
  height: 161.1%;
  background: rgba(0, 0, 0, 0.1);
  -moz-border-radius: 50% / 50%;
  -webkit-border-radius: 50% / 50%;
  border-radius: 50% / 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.character-card-content-detail {
  margin-top: 8%;
  margin-left: .5rem;
  flex: 1;
  animation: fadeIn 0.5s;
}

.character-name, .character-grade, .character-slogan {
  margin-bottom: 0.625vw;
}

.character-card-content .character-role-img {
  position: absolute;
  right: 7%;
  top: -0.5%;

  width: 10.57797%;
  height: auto;
}

.character-animation {
  position: relative;
  width: 48%;
}

.character-animation .star {
  position: absolute;
  width: 0.26vw;
  height: 0.26vw;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 0.365vw 0.1vw #fff;
}

.star:nth-child(71) {
  left: 66.7309%;
  top: 93.8872%;
  transform: scale(0.1118);
  animation: fall-1 20s -14s linear infinite;
}
@keyframes fall-1 {
  0% {
    left: 62.21735%;
    top: 93.8872%;
    opacity: 0.8521;
    transform: scale(0.1118);
  }
  50% {
    opacity: random-opacity0.2091;
  }
  to {
    left: 57.7038%;
    top: 19.8998%;
    transform: scale(0.1118);
    opacity: 0.8521;
  }
}
.star:nth-child(2) {
  left: 68.7434%;
  top: 80.6014%;
  transform: scale(0.9135);
  animation: fall-2 23s -30s linear infinite;
}
@keyframes fall-2 {
  0% {
    left: 68.51995%;
    top: 80.6014%;
    opacity: 0.8273;
    transform: scale(0.9135);
  }
  50% {
    opacity: random-opacity-0.0386;
  }
  to {
    left: 68.2965%;
    top: 4.833%;
    transform: scale(0.9135);
    opacity: 0.8273;
  }
}
.star:nth-child(3) {
  left: 22.959%;
  top: 93.7402%;
  transform: scale(0.0136);
  animation: fall-3 23s -15s linear infinite;
}
@keyframes fall-3 {
  0% {
    left: 26.45755%;
    top: 93.7402%;
    opacity: 0.9965;
    transform: scale(0.0136);
  }
  50% {
    opacity: random-opacity-0.2372;
  }
  to {
    left: 29.9561%;
    top: 16.5701%;
    transform: scale(0.0136);
    opacity: 0.9965;
  }
}
.star:nth-child(4) {
  left: 75.5953%;
  top: 80.7671%;
  transform: scale(0.1082);
  animation: fall-4 25s -15s linear infinite;
}
@keyframes fall-4 {
  0% {
    left: 74.10445%;
    top: 80.7671%;
    opacity: 0.9129;
    transform: scale(0.1082);
  }
  50% {
    opacity: random-opacity-0.2855;
  }
  to {
    left: 72.6136%;
    top: 6.2474%;
    transform: scale(0.1082);
    opacity: 0.9129;
  }
}
.star:nth-child(5) {
  left: 37.64%;
  top: 95.7683%;
  transform: scale(0.2225);
  animation: fall-5 21s -25s linear infinite;
}
@keyframes fall-5 {
  0% {
    left: 37.971%;
    top: 95.7683%;
    opacity: 0.8829;
    transform: scale(0.2225);
  }
  50% {
    opacity: random-opacity-0.1331;
  }
  to {
    left: 38.302%;
    top: 24.6416%;
    transform: scale(0.2225);
    opacity: 0.8829;
  }
}
.star:nth-child(6) {
  left: 32.8528%;
  top: 82.7018%;
  transform: scale(0.0679);
  animation: fall-6 23s -6s linear infinite;
}
@keyframes fall-6 {
  0% {
    left: 34.04945%;
    top: 82.7018%;
    opacity: 0.8412;
    transform: scale(0.0679);
  }
  50% {
    opacity: random-opacity0.0775;
  }
  to {
    left: 35.2461%;
    top: 9.0549%;
    transform: scale(0.0679);
    opacity: 0.8412;
  }
}
.star:nth-child(7) {
  left: 43.767%;
  top: 89.5228%;
  transform: scale(0.411);
  animation: fall-7 22s -9s linear infinite;
}
@keyframes fall-7 {
  0% {
    left: 44.6339%;
    top: 89.5228%;
    opacity: 0.9598;
    transform: scale(0.411);
  }
  50% {
    opacity: random-opacity-0.2987;
  }
  to {
    left: 45.5008%;
    top: 18.2492%;
    transform: scale(0.411);
    opacity: 0.9598;
  }
}
.star:nth-child(8) {
  left: 18.5477%;
  top: 84.4969%;
  transform: scale(0.68);
  animation: fall-8 21s -5s linear infinite;
}
@keyframes fall-8 {
  0% {
    left: 17.206%;
    top: 84.4969%;
    opacity: 0.9195;
    transform: scale(0.68);
  }
  50% {
    opacity: random-opacity0.031;
  }
  to {
    left: 15.8643%;
    top: 5.4403%;
    transform: scale(0.68);
    opacity: 0.9195;
  }
}
.star:nth-child(9) {
  left: 54.6511%;
  top: 98.6795%;
  transform: scale(0.029);
  animation: fall-9 24s -9s linear infinite;
}
@keyframes fall-9 {
  0% {
    left: 50.65195%;
    top: 98.6795%;
    opacity: 0.892;
    transform: scale(0.029);
  }
  50% {
    opacity: random-opacity0.2388;
  }
  to {
    left: 46.6528%;
    top: 25.0171%;
    transform: scale(0.029);
    opacity: 0.892;
  }
}
.star:nth-child(10) {
  left: 68.7943%;
  top: 90.5028%;
  transform: scale(0.8428);
  animation: fall-10 22s -21s linear infinite;
}
@keyframes fall-10 {
  0% {
    left: 72.43825%;
    top: 90.5028%;
    opacity: 0.9928;
    transform: scale(0.8428);
  }
  50% {
    opacity: random-opacity0.2082;
  }
  to {
    left: 76.0822%;
    top: 14.7874%;
    transform: scale(0.8428);
    opacity: 0.9928;
  }
}
.star:nth-child(11) {
  left: 57.4736%;
  top: 80.3308%;
  transform: scale(0.2665);
  animation: fall-11 25s -27s linear infinite;
}
@keyframes fall-11 {
  0% {
    left: 60.5284%;
    top: 80.3308%;
    opacity: 0.8474;
    transform: scale(0.2665);
  }
  50% {
    opacity: random-opacity0.1151;
  }
  to {
    left: 63.5832%;
    top: 2.6441%;
    transform: scale(0.2665);
    opacity: 0.8474;
  }
}
.star:nth-child(12) {
  left: 18.0994%;
  top: 84.0608%;
  transform: scale(0.8205);
  animation: fall-12 25s -5s linear infinite;
}
@keyframes fall-12 {
  0% {
    left: 19.7886%;
    top: 84.0608%;
    opacity: 0.9432;
    transform: scale(0.8205);
  }
  50% {
    opacity: random-opacity-0.0924;
  }
  to {
    left: 21.4778%;
    top: 5.9171%;
    transform: scale(0.8205);
    opacity: 0.9432;
  }
}
.star:nth-child(13) {
  left: 35.4755%;
  top: 82.6242%;
  transform: scale(0.9859);
  animation: fall-13 18s -15s linear infinite;
}
@keyframes fall-13 {
  0% {
    left: 33.8662%;
    top: 82.6242%;
    opacity: 0.9589;
    transform: scale(0.9859);
  }
  50% {
    opacity: random-opacity-0.2775;
  }
  to {
    left: 32.2569%;
    top: 2.9936%;
    transform: scale(0.9859);
    opacity: 0.9589;
  }
}
.star:nth-child(14) {
  left: 51.9352%;
  top: 92.7912%;
  transform: scale(0.0907);
  animation: fall-14 19s -7s linear infinite;
}
@keyframes fall-14 {
  0% {
    left: 47.21185%;
    top: 92.7912%;
    opacity: 0.9443;
    transform: scale(0.0907);
  }
  50% {
    opacity: random-opacity0.2171;
  }
  to {
    left: 42.4885%;
    top: 18.007%;
    transform: scale(0.0907);
    opacity: 0.9443;
  }
}
.star:nth-child(15) {
  left: 41.1352%;
  top: 88.4599%;
  transform: scale(0.2069);
  animation: fall-15 17s -20s linear infinite;
}
@keyframes fall-15 {
  0% {
    left: 40.6348%;
    top: 88.4599%;
    opacity: 0.9897;
    transform: scale(0.2069);
  }
  50% {
    opacity: random-opacity0.2973;
  }
  to {
    left: 40.1344%;
    top: 16.2941%;
    transform: scale(0.2069);
    opacity: 0.9897;
  }
}
.star:nth-child(16) {
  left: 40.834%;
  top: 83.7847%;
  transform: scale(0.2872);
  animation: fall-16 20s -15s linear infinite;
}
@keyframes fall-16 {
  0% {
    left: 39.11835%;
    top: 83.7847%;
    opacity: 0.9186;
    transform: scale(0.2872);
  }
  50% {
    opacity: random-opacity0.1815;
  }
  to {
    left: 37.4027%;
    top: 10.2259%;
    transform: scale(0.2872);
    opacity: 0.9186;
  }
}
.star:nth-child(17) {
  left: 65.7059%;
  top: 99.254%;
  transform: scale(0.0185);
  animation: fall-17 17s -27s linear infinite;
}
@keyframes fall-17 {
  0% {
    left: 63.4825%;
    top: 99.254%;
    opacity: 0.9696;
    transform: scale(0.0185);
  }
  50% {
    opacity: random-opacity-0.1483;
  }
  to {
    left: 61.2591%;
    top: 24.2039%;
    transform: scale(0.0185);
    opacity: 0.9696;
  }
}
.star:nth-child(18) {
  left: 76.6234%;
  top: 95.0927%;
  transform: scale(0.468);
  animation: fall-18 25s -24s linear infinite;
}
@keyframes fall-18 {
  0% {
    left: 80.77715%;
    top: 95.0927%;
    opacity: 0.8471;
    transform: scale(0.468);
  }
  50% {
    opacity: random-opacity-0.2334;
  }
  to {
    left: 84.9309%;
    top: 20.5473%;
    transform: scale(0.468);
    opacity: 0.8471;
  }
}
.star:nth-child(19) {
  left: 58.0268%;
  top: 93.3841%;
  transform: scale(0.7247);
  animation: fall-19 24s -15s linear infinite;
}
@keyframes fall-19 {
  0% {
    left: 56.76115%;
    top: 93.3841%;
    opacity: 0.9847;
    transform: scale(0.7247);
  }
  50% {
    opacity: random-opacity-0.1932;
  }
  to {
    left: 55.4955%;
    top: 19.5314%;
    transform: scale(0.7247);
    opacity: 0.9847;
  }
}
.star:nth-child(20) {
  left: 27.4641%;
  top: 92.5951%;
  transform: scale(0.5723);
  animation: fall-20 24s -24s linear infinite;
}
@keyframes fall-20 {
  0% {
    left: 29.6496%;
    top: 92.5951%;
    opacity: 0.9278;
    transform: scale(0.5723);
  }
  50% {
    opacity: random-opacity-0.011;
  }
  to {
    left: 31.8351%;
    top: 15.4766%;
    transform: scale(0.5723);
    opacity: 0.9278;
  }
}
.star:nth-child(21) {
  left: 77.2934%;
  top: 99.6094%;
  transform: scale(0.1684);
  animation: fall-21 24s -9s linear infinite;
}
@keyframes fall-21 {
  0% {
    left: 78.4383%;
    top: 99.6094%;
    opacity: 0.8235;
    transform: scale(0.1684);
  }
  50% {
    opacity: random-opacity-0.195;
  }
  to {
    left: 79.5832%;
    top: 25.756%;
    transform: scale(0.1684);
    opacity: 0.8235;
  }
}
.star:nth-child(22) {
  left: 23.5505%;
  top: 89.7586%;
  transform: scale(0.7809);
  animation: fall-22 22s -18s linear infinite;
}
@keyframes fall-22 {
  0% {
    left: 22.3361%;
    top: 89.7586%;
    opacity: 0.9982;
    transform: scale(0.7809);
  }
  50% {
    opacity: random-opacity-0.1632;
  }
  to {
    left: 21.1217%;
    top: 17.4653%;
    transform: scale(0.7809);
    opacity: 0.9982;
  }
}
.star:nth-child(23) {
  left: 70.4044%;
  top: 88.849%;
  transform: scale(0.8494);
  animation: fall-23 19s -16s linear infinite;
}
@keyframes fall-23 {
  0% {
    left: 71.89115%;
    top: 88.849%;
    opacity: 0.8494;
    transform: scale(0.8494);
  }
  50% {
    opacity: random-opacity0.151;
  }
  to {
    left: 73.3779%;
    top: 11.0532%;
    transform: scale(0.8494);
    opacity: 0.8494;
  }
}
.star:nth-child(24) {
  left: 68.8589%;
  top: 82.151%;
  transform: scale(0.7227);
  animation: fall-24 24s -19s linear infinite;
}
@keyframes fall-24 {
  0% {
    left: 71.00765%;
    top: 82.151%;
    opacity: 0.9856;
    transform: scale(0.7227);
  }
  50% {
    opacity: random-opacity0.0427;
  }
  to {
    left: 73.1564%;
    top: 7.4616%;
    transform: scale(0.7227);
    opacity: 0.9856;
  }
}
.star:nth-child(25) {
  left: 35.8256%;
  top: 85.8652%;
  transform: scale(0.8068);
  animation: fall-25 23s -20s linear infinite;
}
@keyframes fall-25 {
  0% {
    left: 35.5055%;
    top: 85.8652%;
    opacity: 0.8625;
    transform: scale(0.8068);
  }
  50% {
    opacity: random-opacity-0.0666;
  }
  to {
    left: 35.1854%;
    top: 6.3955%;
    transform: scale(0.8068);
    opacity: 0.8625;
  }
}
.star:nth-child(26) {
  left: 63.3074%;
  top: 81.4087%;
  transform: scale(0.8628);
  animation: fall-26 20s -6s linear infinite;
}
@keyframes fall-26 {
  0% {
    left: 59.4464%;
    top: 81.4087%;
    opacity: 0.9586;
    transform: scale(0.8628);
  }
  50% {
    opacity: random-opacity-0.1779;
  }
  to {
    left: 55.5854%;
    top: 8.5983%;
    transform: scale(0.8628);
    opacity: 0.9586;
  }
}
.star:nth-child(27) {
  left: 17.3982%;
  top: 87.8748%;
  transform: scale(0.2234);
  animation: fall-27 17s -5s linear infinite;
}
@keyframes fall-27 {
  0% {
    left: 14.54255%;
    top: 87.8748%;
    opacity: 0.9732;
    transform: scale(0.2234);
  }
  50% {
    opacity: random-opacity-0.0688;
  }
  to {
    left: 11.6869%;
    top: 11.3667%;
    transform: scale(0.2234);
    opacity: 0.9732;
  }
}
.star:nth-child(28) {
  left: 39.9954%;
  top: 84.457%;
  transform: scale(0.9502);
  animation: fall-28 21s -13s linear infinite;
}
@keyframes fall-28 {
  0% {
    left: 38.62575%;
    top: 84.457%;
    opacity: 0.8209;
    transform: scale(0.9502);
  }
  50% {
    opacity: random-opacity-0.2472;
  }
  to {
    left: 37.2561%;
    top: 13.1091%;
    transform: scale(0.9502);
    opacity: 0.8209;
  }
}
.star:nth-child(29) {
  left: 74.7302%;
  top: 97.8258%;
  transform: scale(0.5901);
  animation: fall-29 24s -20s linear infinite;
}
@keyframes fall-29 {
  0% {
    left: 72.02965%;
    top: 97.8258%;
    opacity: 0.8266;
    transform: scale(0.5901);
  }
  50% {
    opacity: random-opacity0.1741;
  }
  to {
    left: 69.3291%;
    top: 20.1602%;
    transform: scale(0.5901);
    opacity: 0.8266;
  }
}
.star:nth-child(30) {
  left: 61.7296%;
  top: 85.4132%;
  transform: scale(0.031);
  animation: fall-30 20s -25s linear infinite;
}
@keyframes fall-30 {
  0% {
    left: 57.9114%;
    top: 85.4132%;
    opacity: 0.8663;
    transform: scale(0.031);
  }
  50% {
    opacity: random-opacity0.2925;
  }
  to {
    left: 54.0932%;
    top: 9.4193%;
    transform: scale(0.031);
    opacity: 0.8663;
  }
}
.star:nth-child(31) {
  left: 71.2442%;
  top: 82.1942%;
  transform: scale(0.7579);
  animation: fall-31 16s -25s linear infinite;
}
@keyframes fall-31 {
  0% {
    left: 66.6217%;
    top: 82.1942%;
    opacity: 0.8251;
    transform: scale(0.7579);
  }
  50% {
    opacity: random-opacity0.0996;
  }
  to {
    left: 61.9992%;
    top: 8.4336%;
    transform: scale(0.7579);
    opacity: 0.8251;
  }
}
.star:nth-child(32) {
  left: 59.986%;
  top: 82.8711%;
  transform: scale(0.8822);
  animation: fall-32 21s -16s linear infinite;
}
@keyframes fall-32 {
  0% {
    left: 63.40545%;
    top: 82.8711%;
    opacity: 0.9434;
    transform: scale(0.8822);
  }
  50% {
    opacity: random-opacity-0.2773;
  }
  to {
    left: 66.8249%;
    top: 11.8688%;
    transform: scale(0.8822);
    opacity: 0.9434;
  }
}
.star:nth-child(33) {
  left: 15.206%;
  top: 99.5518%;
  transform: scale(0.2301);
  animation: fall-33 16s -27s linear infinite;
}
@keyframes fall-33 {
  0% {
    left: 18.98975%;
    top: 99.5518%;
    opacity: 0.8926;
    transform: scale(0.2301);
  }
  50% {
    opacity: random-opacity-0.1409;
  }
  to {
    left: 22.7735%;
    top: 29.3551%;
    transform: scale(0.2301);
    opacity: 0.8926;
  }
}
.star:nth-child(34) {
  left: 38.1675%;
  top: 88.4059%;
  transform: scale(0.6705);
  animation: fall-34 20s -23s linear infinite;
}
@keyframes fall-34 {
  0% {
    left: 34.19985%;
    top: 88.4059%;
    opacity: 0.971;
    transform: scale(0.6705);
  }
  50% {
    opacity: random-opacity0.0899;
  }
  to {
    left: 30.2322%;
    top: 14.0421%;
    transform: scale(0.6705);
    opacity: 0.971;
  }
}
.star:nth-child(35) {
  left: 74.9043%;
  top: 85.9814%;
  transform: scale(0.8161);
  animation: fall-35 19s -3s linear infinite;
}
@keyframes fall-35 {
  0% {
    left: 78.7222%;
    top: 85.9814%;
    opacity: 0.8728;
    transform: scale(0.8161);
  }
  50% {
    opacity: random-opacity0.007;
  }
  to {
    left: 82.5401%;
    top: 8.0299%;
    transform: scale(0.8161);
    opacity: 0.8728;
  }
}
.star:nth-child(36) {
  left: 70.6542%;
  top: 94.6371%;
  transform: scale(0.3687);
  animation: fall-36 23s -1s linear infinite;
}
@keyframes fall-36 {
  0% {
    left: 71.7949%;
    top: 94.6371%;
    opacity: 0.8898;
    transform: scale(0.3687);
  }
  50% {
    opacity: random-opacity-0.1792;
  }
  to {
    left: 72.9356%;
    top: 22.1649%;
    transform: scale(0.3687);
    opacity: 0.8898;
  }
}
.star:nth-child(37) {
  left: 53.2062%;
  top: 87.9613%;
  transform: scale(0.6263);
  animation: fall-37 20s -1s linear infinite;
}
@keyframes fall-37 {
  0% {
    left: 48.39315%;
    top: 87.9613%;
    opacity: 0.9025;
    transform: scale(0.6263);
  }
  50% {
    opacity: random-opacity-0.0533;
  }
  to {
    left: 43.5801%;
    top: 14.0797%;
    transform: scale(0.6263);
    opacity: 0.9025;
  }
}
.star:nth-child(38) {
  left: 59.2971%;
  top: 83.6705%;
  transform: scale(0.0092);
  animation: fall-38 16s -1s linear infinite;
}
@keyframes fall-38 {
  0% {
    left: 54.3003%;
    top: 83.6705%;
    opacity: 0.9692;
    transform: scale(0.0092);
  }
  50% {
    opacity: random-opacity0.082;
  }
  to {
    left: 49.3035%;
    top: 12.2915%;
    transform: scale(0.0092);
    opacity: 0.9692;
  }
}
.star:nth-child(39) {
  left: 78.6253%;
  top: 90.855%;
  transform: scale(0.0108);
  animation: fall-39 22s -28s linear infinite;
}
@keyframes fall-39 {
  0% {
    left: 81.5894%;
    top: 90.855%;
    opacity: 0.815;
    transform: scale(0.0108);
  }
  50% {
    opacity: random-opacity0.0941;
  }
  to {
    left: 84.5535%;
    top: 19.3812%;
    transform: scale(0.0108);
    opacity: 0.815;
  }
}
.star:nth-child(40) {
  left: 57.8914%;
  top: 80.7816%;
  transform: scale(0.6949);
  animation: fall-40 19s -19s linear infinite;
}
@keyframes fall-40 {
  0% {
    left: 62.71135%;
    top: 80.7816%;
    opacity: 0.9432;
    transform: scale(0.6949);
  }
  50% {
    opacity: random-opacity0.1997;
  }
  to {
    left: 67.5313%;
    top: 6.8651%;
    transform: scale(0.6949);
    opacity: 0.9432;
  }
}
.star:nth-child(41) {
  left: 67.9272%;
  top: 82.0928%;
  transform: scale(0.4027);
  animation: fall-41 20s -7s linear infinite;
}
@keyframes fall-41 {
  0% {
    left: 63.9604%;
    top: 82.0928%;
    opacity: 0.8035;
    transform: scale(0.4027);
  }
  50% {
    opacity: random-opacity0.062;
  }
  to {
    left: 59.9936%;
    top: 2.8353%;
    transform: scale(0.4027);
    opacity: 0.8035;
  }
}
.star:nth-child(42) {
  left: 59.475%;
  top: 84.0223%;
  transform: scale(0.9744);
  animation: fall-42 19s -5s linear infinite;
}
@keyframes fall-42 {
  0% {
    left: 62.821%;
    top: 84.0223%;
    opacity: 0.9194;
    transform: scale(0.9744);
  }
  50% {
    opacity: random-opacity0.1002;
  }
  to {
    left: 66.167%;
    top: 8.8461%;
    transform: scale(0.9744);
    opacity: 0.9194;
  }
}
.star:nth-child(43) {
  left: 30.96%;
  top: 90.3526%;
  transform: scale(0.9881);
  animation: fall-43 21s -25s linear infinite;
}
@keyframes fall-43 {
  0% {
    left: 30.94755%;
    top: 90.3526%;
    opacity: 0.8112;
    transform: scale(0.9881);
  }
  50% {
    opacity: random-opacity-0.2152;
  }
  to {
    left: 30.9351%;
    top: 14.5807%;
    transform: scale(0.9881);
    opacity: 0.8112;
  }
}
.star:nth-child(44) {
  left: 75.2971%;
  top: 91.7162%;
  transform: scale(0.0934);
  animation: fall-44 20s -19s linear infinite;
}
@keyframes fall-44 {
  0% {
    left: 75.178%;
    top: 91.7162%;
    opacity: 0.911;
    transform: scale(0.0934);
  }
  50% {
    opacity: random-opacity0.0984;
  }
  to {
    left: 75.0589%;
    top: 21.5105%;
    transform: scale(0.0934);
    opacity: 0.911;
  }
}
.star:nth-child(45) {
  left: 57.8692%;
  top: 88.1414%;
  transform: scale(0.2461);
  animation: fall-45 19s -16s linear infinite;
}
@keyframes fall-45 {
  0% {
    left: 53.4507%;
    top: 88.1414%;
    opacity: 0.869;
    transform: scale(0.2461);
  }
  50% {
    opacity: random-opacity0.067;
  }
  to {
    left: 49.0322%;
    top: 15.3635%;
    transform: scale(0.2461);
    opacity: 0.869;
  }
}
.star:nth-child(46) {
  left: 48.6239%;
  top: 92.3002%;
  transform: scale(0.8872);
  animation: fall-46 16s -5s linear infinite;
}
@keyframes fall-46 {
  0% {
    left: 53.10775%;
    top: 92.3002%;
    opacity: 0.9947;
    transform: scale(0.8872);
  }
  50% {
    opacity: random-opacity-0.1237;
  }
  to {
    left: 57.5916%;
    top: 13.5451%;
    transform: scale(0.8872);
    opacity: 0.9947;
  }
}
.star:nth-child(47) {
  left: 25.0386%;
  top: 83.3539%;
  transform: scale(0.7502);
  animation: fall-47 25s -3s linear infinite;
}
@keyframes fall-47 {
  0% {
    left: 29.6132%;
    top: 83.3539%;
    opacity: 0.9366;
    transform: scale(0.7502);
  }
  50% {
    opacity: random-opacity-0.156;
  }
  to {
    left: 34.1878%;
    top: 12.2958%;
    transform: scale(0.7502);
    opacity: 0.9366;
  }
}
.star:nth-child(48) {
  left: 26.0915%;
  top: 80.5708%;
  transform: scale(0.0841);
  animation: fall-48 15s -29s linear infinite;
}
@keyframes fall-48 {
  0% {
    left: 29.1773%;
    top: 80.5708%;
    opacity: 0.8561;
    transform: scale(0.0841);
  }
  50% {
    opacity: random-opacity0.2795;
  }
  to {
    left: 32.2631%;
    top: 0.7279%;
    transform: scale(0.0841);
    opacity: 0.8561;
  }
}
.star:nth-child(49) {
  left: 52.9574%;
  top: 96.3012%;
  transform: scale(0.3726);
  animation: fall-49 20s -1s linear infinite;
}
@keyframes fall-49 {
  0% {
    left: 50.44635%;
    top: 96.3012%;
    opacity: 0.9583;
    transform: scale(0.3726);
  }
  50% {
    opacity: random-opacity0.2811;
  }
  to {
    left: 47.9353%;
    top: 17.3146%;
    transform: scale(0.3726);
    opacity: 0.9583;
  }
}
.star:nth-child(50) {
  left: 54.9573%;
  top: 97.4917%;
  transform: scale(0.364);
  animation: fall-50 16s -21s linear infinite;
}
@keyframes fall-50 {
  0% {
    left: 52.667%;
    top: 97.4917%;
    opacity: 0.9729;
    transform: scale(0.364);
  }
  50% {
    opacity: random-opacity-0.029;
  }
  to {
    left: 50.3767%;
    top: 26.2947%;
    transform: scale(0.364);
    opacity: 0.9729;
  }
}
.star:nth-child(51) {
  left: 53.3065%;
  top: 91.0451%;
  transform: scale(0.0851);
  animation: fall-51 20s -9s linear infinite;
}
@keyframes fall-51 {
  0% {
    left: 50.59415%;
    top: 91.0451%;
    opacity: 0.8119;
    transform: scale(0.0851);
  }
  50% {
    opacity: random-opacity-0.0097;
  }
  to {
    left: 47.8818%;
    top: 13.6029%;
    transform: scale(0.0851);
    opacity: 0.8119;
  }
}
.star:nth-child(52) {
  left: 74.4114%;
  top: 89.1013%;
  transform: scale(0.0362);
  animation: fall-52 25s -15s linear infinite;
}
@keyframes fall-52 {
  0% {
    left: 79.1367%;
    top: 89.1013%;
    opacity: 0.9182;
    transform: scale(0.0362);
  }
  50% {
    opacity: random-opacity0.1956;
  }
  to {
    left: 83.862%;
    top: 11.5245%;
    transform: scale(0.0362);
    opacity: 0.9182;
  }
}
.star:nth-child(53) {
  left: 18.6329%;
  top: 88.1842%;
  transform: scale(0.5232);
  animation: fall-53 25s -14s linear infinite;
}
@keyframes fall-53 {
  0% {
    left: 15.0886%;
    top: 88.1842%;
    opacity: 0.9122;
    transform: scale(0.5232);
  }
  50% {
    opacity: random-opacity-0.1087;
  }
  to {
    left: 11.5443%;
    top: 11.8061%;
    transform: scale(0.5232);
    opacity: 0.9122;
  }
}
.star:nth-child(54) {
  left: 24.5166%;
  top: 80.9498%;
  transform: scale(0.786);
  animation: fall-54 16s -18s linear infinite;
}
@keyframes fall-54 {
  0% {
    left: 23.3083%;
    top: 80.9498%;
    opacity: 0.8914;
    transform: scale(0.786);
  }
  50% {
    opacity: random-opacity0.1688;
  }
  to {
    left: 22.1%;
    top: 6.9338%;
    transform: scale(0.786);
    opacity: 0.8914;
  }
}
.star:nth-child(55) {
  left: 16.1164%;
  top: 87.3471%;
  transform: scale(0.1389);
  animation: fall-55 24s -23s linear infinite;
}
@keyframes fall-55 {
  0% {
    left: 11.3874%;
    top: 87.3471%;
    opacity: 0.8202;
    transform: scale(0.1389);
  }
  50% {
    opacity: random-opacity0.2958;
  }
  to {
    left: 6.6584%;
    top: 11.8867%;
    transform: scale(0.1389);
    opacity: 0.8202;
  }
}
.star:nth-child(56) {
  left: 47.2085%;
  top: 89.5067%;
  transform: scale(0.2619);
  animation: fall-56 23s -17s linear infinite;
}
@keyframes fall-56 {
  0% {
    left: 44.85725%;
    top: 89.5067%;
    opacity: 0.8446;
    transform: scale(0.2619);
  }
  50% {
    opacity: random-opacity-0.2557;
  }
  to {
    left: 42.506%;
    top: 15.228%;
    transform: scale(0.2619);
    opacity: 0.8446;
  }
}
.star:nth-child(57) {
  left: 76.1473%;
  top: 89.4274%;
  transform: scale(0.3161);
  animation: fall-57 24s -18s linear infinite;
}
@keyframes fall-57 {
  0% {
    left: 74.7115%;
    top: 89.4274%;
    opacity: 0.9278;
    transform: scale(0.3161);
  }
  50% {
    opacity: random-opacity-0.1815;
  }
  to {
    left: 73.2757%;
    top: 19.1478%;
    transform: scale(0.3161);
    opacity: 0.9278;
  }
}
.star:nth-child(58) {
  left: 42.7424%;
  top: 84.8269%;
  transform: scale(0.3666);
  animation: fall-58 17s -27s linear infinite;
}
@keyframes fall-58 {
  0% {
    left: 47.3454%;
    top: 84.8269%;
    opacity: 0.885;
    transform: scale(0.3666);
  }
  50% {
    opacity: random-opacity-0.2563;
  }
  to {
    left: 51.9484%;
    top: 5.054%;
    transform: scale(0.3666);
    opacity: 0.885;
  }
}
.star:nth-child(59) {
  left: 21.8308%;
  top: 84.7467%;
  transform: scale(0.7734);
  animation: fall-59 21s -5s linear infinite;
}
@keyframes fall-59 {
  0% {
    left: 21.7324%;
    top: 84.7467%;
    opacity: 0.9612;
    transform: scale(0.7734);
  }
  50% {
    opacity: random-opacity-0.183;
  }
  to {
    left: 21.634%;
    top: 6.6914%;
    transform: scale(0.7734);
    opacity: 0.9612;
  }
}
.star:nth-child(60) {
  left: 39.2842%;
  top: 85.347%;
  transform: scale(0.9746);
  animation: fall-60 18s -6s linear infinite;
}
@keyframes fall-60 {
  0% {
    left: 37.92965%;
    top: 85.347%;
    opacity: 0.9683;
    transform: scale(0.9746);
  }
  50% {
    opacity: random-opacity0.0477;
  }
  to {
    left: 36.5751%;
    top: 7.9027%;
    transform: scale(0.9746);
    opacity: 0.9683;
  }
}
.star:nth-child(61) {
  left: 46.9301%;
  top: 86.7745%;
  transform: scale(0.9054);
  animation: fall-61 22s -29s linear infinite;
}
@keyframes fall-61 {
  0% {
    left: 49.29715%;
    top: 86.7745%;
    opacity: 0.9252;
    transform: scale(0.9054);
  }
  50% {
    opacity: random-opacity-0.1498;
  }
  to {
    left: 51.6642%;
    top: 10.897%;
    transform: scale(0.9054);
    opacity: 0.9252;
  }
}
.star:nth-child(62) {
  left: 28.2212%;
  top: 81.2673%;
  transform: scale(0.7526);
  animation: fall-62 23s -24s linear infinite;
}
@keyframes fall-62 {
  0% {
    left: 30.47435%;
    top: 81.2673%;
    opacity: 0.9797;
    transform: scale(0.7526);
  }
  50% {
    opacity: random-opacity0.2982;
  }
  to {
    left: 32.7275%;
    top: 2.4341%;
    transform: scale(0.7526);
    opacity: 0.9797;
  }
}
.star:nth-child(63) {
  left: 56.9353%;
  top: 86.8709%;
  transform: scale(0.3489);
  animation: fall-63 19s -12s linear infinite;
}
@keyframes fall-63 {
  0% {
    left: 59.8622%;
    top: 86.8709%;
    opacity: 0.8172;
    transform: scale(0.3489);
  }
  50% {
    opacity: random-opacity0.1121;
  }
  to {
    left: 62.7891%;
    top: 13.7129%;
    transform: scale(0.3489);
    opacity: 0.8172;
  }
}
.star:nth-child(64) {
  left: 68.2027%;
  top: 84.9861%;
  transform: scale(0.3597);
  animation: fall-64 20s -24s linear infinite;
}
@keyframes fall-64 {
  0% {
    left: 63.29915%;
    top: 84.9861%;
    opacity: 0.8837;
    transform: scale(0.3597);
  }
  50% {
    opacity: random-opacity-0.1269;
  }
  to {
    left: 58.3956%;
    top: 12.407%;
    transform: scale(0.3597);
    opacity: 0.8837;
  }
}
.star:nth-child(65) {
  left: 18.215%;
  top: 84.3063%;
  transform: scale(0.2536);
  animation: fall-65 15s -23s linear infinite;
}
@keyframes fall-65 {
  0% {
    left: 19.43885%;
    top: 84.3063%;
    opacity: 0.9235;
    transform: scale(0.2536);
  }
  50% {
    opacity: random-opacity0.1553;
  }
  to {
    left: 20.6627%;
    top: 13.157%;
    transform: scale(0.2536);
    opacity: 0.9235;
  }
}
.star:nth-child(66) {
  left: 21.3275%;
  top: 91.5984%;
  transform: scale(0.5936);
  animation: fall-66 16s -14s linear infinite;
}
@keyframes fall-66 {
  0% {
    left: 23.95215%;
    top: 91.5984%;
    opacity: 0.8345;
    transform: scale(0.5936);
  }
  50% {
    opacity: random-opacity-0.0289;
  }
  to {
    left: 26.5768%;
    top: 13.4006%;
    transform: scale(0.5936);
    opacity: 0.8345;
  }
}
.star:nth-child(67) {
  left: 46.626%;
  top: 91.4083%;
  transform: scale(0.1609);
  animation: fall-67 25s -20s linear infinite;
}
@keyframes fall-67 {
  0% {
    left: 50.2992%;
    top: 91.4083%;
    opacity: 0.8355;
    transform: scale(0.1609);
  }
  50% {
    opacity: random-opacity-0.1958;
  }
  to {
    left: 53.9724%;
    top: 13.2951%;
    transform: scale(0.1609);
    opacity: 0.8355;
  }
}
.star:nth-child(68) {
  left: 54.4329%;
  top: 93.9111%;
  transform: scale(0.9037);
  animation: fall-68 15s -2s linear infinite;
}
@keyframes fall-68 {
  0% {
    left: 52.8168%;
    top: 93.9111%;
    opacity: 0.8428;
    transform: scale(0.9037);
  }
  50% {
    opacity: random-opacity-0.2084;
  }
  to {
    left: 51.2007%;
    top: 16.0654%;
    transform: scale(0.9037);
    opacity: 0.8428;
  }
}
.star:nth-child(69) {
  left: 12.2581%;
  top: 80.8086%;
  transform: scale(0.6919);
  animation: fall-69 17s -30s linear infinite;
}
@keyframes fall-69 {
  0% {
    left: 8.78525%;
    top: 80.8086%;
    opacity: 0.8465;
    transform: scale(0.6919);
  }
  50% {
    opacity: random-opacity0.0632;
  }
  to {
    left: 5.3124%;
    top: 6.1138%;
    transform: scale(0.6919);
    opacity: 0.8465;
  }
}
.star:nth-child(70) {
  left: 42.2022%;
  top: 95.3414%;
  transform: scale(0.3381);
  animation: fall-70 25s -16s linear infinite;
}
@keyframes fall-70 {
  0% {
    left: 44.329%;
    top: 95.3414%;
    opacity: 0.9341;
    transform: scale(0.3381);
  }
  50% {
    opacity: random-opacity0.0757;
  }
  to {
    left: 46.4558%;
    top: 19.2047%;
    transform: scale(0.3381);
    opacity: 0.9341;
  }
}
.role-character {
  height: 100%;
  width: auto;
}

.role-character-dragonbone {
  width: 100%;
}

.role-character-dragonbone canvas{
  position: absolute;
    left: 50%;
    bottom: 0%;
    transform: translate(-43%, 100%);
}

.character-name {
  height: 8.646%;
  width: auto;
  object-fit: contain;
}

.character-grade {
  display: block;
  height: 8%;
  width: fit-content;
}

.UR-setting {
  height: 40%;
  vertical-align: sub;
}

.character-setting {
  background: rgba(0, 0, 0, 0.44);
  border-top-left-radius: 27px;
  border-bottom-left-radius: 27px;
  width: 100%;
  height: 2.760416vw;
  padding-left: 1.25vw;
  margin-left: -1.25vw;
  font-size: 1.0416vw;
  line-height: 2.760416vw;
  color: #fff;
}

.UR-slogan {
  margin-top: 5%;
  height: auto;
  width: 70%;
  align-self: center;
}

.character-slogan {
  font-family: "Arial";
  font-weight: 600;
  font-style: italic;
  font-size: 2.03125vw;
  line-height: 2.03125vw;
  color: #fff;
  margin-top: 0.75rem;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  margin-left: -0.5rem;
}

.character-slogan span {
  width: 100%;
}

.character-slogan span:first-child {
  text-align: start;
}

.character-slogan span:last-child {
  text-align: end;
  margin-left: -0.5rem;
}

.selected-card a {
  width: fit-content;
  margin: 4% auto;
}

.buy-button {
  width: 8.49vw;
  height: 2.448vw;
  background-image: url(../images/link/button/buy-normal.png);
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 600;
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 0.2s, box-shadow 0.2s, background-image 0.2s;
  text-shadow: 0 2px 5px #000000aa;
  font-size: 0.73vw;
  white-space: nowrap;
}

.buy-button:hover {
  background-image: url(../images/link/button/buy-hover.png);
  transform: translateY(-1px);
}

.buy-button:active {
  background-image: url(../images/link/button/buy-active.png);
  transform: translateY(2px);
  box-shadow: 0 0 1px #00000033;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 1400px) {
  .character-card-bg {
    border: 5px solid #ffc600;
  }
}

@media (max-width: 1200px) {
  .character-card-bg {
    border: 4px solid #ffc600;
  }
}

@media (max-width: 768px) {
  .character-card-bg {
    border: 4px solid #ffc600;
  }
}

@media (max-width: 576px) {
  .character-card-bg {
    border: 3px solid #ffc600;
  }
}

@media (max-width: 600px) {
  .character-card {
    width: 25.93458vw;
    height: calc(25.93458vw * calc(136 / 111));
    transform: translate(0, 0);
    zoom: 1;
    position: static;
  }  

  .character-card > img {
    width: 26vw;
    height: auto;
  }

  .character-card-SSSR-bg {
    height: 83.5%;
    bottom: 3.5%;
  }
}
