.swiper-pagination-bullet {
  width: 5vw;
  height: 1vh;
  border-radius: 0.5vh !important;
  margin: 0 15px !important;
  background: #727272 !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background: #fec767 !important ;
}

.swiper-pagination {
  bottom: 8vh !important;
}

.kingdom-carousel-poker-card {
  animation: onTop 8s 1.4s ease infinite;
}

.kingdom-carousel-poker > img {
  width: 94%;
  height: auto;
}

.kingdom-carousel-poker-card.one {
  left: 0;
  top: 0;
  z-index: 2;
  animation-name: onTop;
  position: absolute;
  /* filter: brightness(0.3); */
}

.kingdom-carousel-poker-card.two {
  left: 30px;
  top: 30px;
  z-index: 1;
  animation-name: onMiddle;
  filter: brightness(0.3);
  position: absolute;
}

@media (max-width: 600px) {
  .swiper-pagination {
    display: none;
  }

  .kingdom-carousel-poker-card.one {
    animation-name: onTop;
  }

  .kingdom-carousel-poker-card.two {
    animation-name: onMiddle;
  }
}

@keyframes onTop {
  10% {
    transform: translateY(-110%);
    z-index: 1;
    left: 30px;
    top: 30px;
    filter: brightness(0.3);
  }
  25% {
    transform: translateY(0);
    z-index: 0;
    left: 30px;
    top: 30px;
    filter: brightness(0.3);
  }
  40% {
    transform: translateY(0);
    z-index: 0;
    left: 30px;
    top: 30px;
    filter: brightness(0.3);
  }
  55% {
    transform: translateY(0);
    z-index: 2;
    left: 0px;
    top: 0px;
    filter: brightness(1);
  }
  70% {
    transform: translateY(0);
    z-index: 1;
    left: 0px;
    top: 0px;
    filter: brightness(1);
  }
  85% {
    transform: translateY(0);
    z-index: 1;
    left: 0;
    top: 0;
    filter: brightness(1);
  }
}

@keyframes onMiddle {
  25% {
    transform: translateY(0);
    z-index: 1;
    left: 0;
    top: 0;
    filter: brightness(1);
  }
  40% {
    transform: translateY(0);
    z-index: 1;
    left: 0;
    top: 0;
    filter: brightness(1);
  }
  55% {
    transform: translateY(-110%);
    z-index: 1;
    left: 30px;
    top: 30px;
    filter: brightness(0.3);
  }
  70% {
    transform: translateY(0);
    z-index: 0;
    left: 30px;
    top: 30px;
    filter: brightness(0.3);
  }
  85% {
    transform: translateY(0);
    z-index: 0;
    left: 30px;
    top: 30px;
    filter: brightness(0.3);
  }
  100% {
    transform: translateY(0);
    z-index: 0;
    left: 30px;
    top: 30px;
    filter: brightness(0.3);
  }
}

.swiper-wrapper .swiper-slide:last-child {
  height: unset !important;
  line-height: unset !important;
}
