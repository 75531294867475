footer {
  height: 25vh;
  background: linear-gradient(to right, #1f2a37, #141b24);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: #2a384a 0.5vh solid;
  box-sizing: border-box;
  padding: 1.5% 8%;
  font-family: "Footer";
}
footer .footer-left {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  min-width: 480px;
}
footer .footer-right {
  height: 100%;
}

footer .footer-left img {
  height: 8vh;
  display: block;
}

footer .footer-left .footer-jump-box {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 0.9vw;
  width: 70%;
  gap: 2vw;
}

footer .footer-left .footer-jump-item {
  display: flex;
  flex-wrap: wrap;
  width: 28%;
}

footer .footer-left .footer-jump-item:nth-child(2) {
  margin-left: 2.6vw;
}

footer .footer-left .footer-jump-item label {
  width: 100%;
  color: #787e85;
  font-size: 1.1vw;
  font-weight: 600;
  line-height: 3vh;
  margin-bottom: 1vh;
}

footer .footer-left .footer-jump-item a {
  width: 100%;
  text-decoration: none;
  color: #ffffff;
  line-height: 3vh;
  white-space: nowrap;
}

footer .footer-left ul {
  display: flex;
  color: #fff;
  list-style: none;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 65%;
  padding-left: 0;
  font-size: 1vw;
  height: 100%;
  margin-block-start: 0;
  margin-block-end: 0;
}

footer .footer-left ul li {
  list-style: none;
  width: 50%;
  height: 25%;
  display: flex;
  align-items: center;
}

footer .footer-left ul li span {
  height: 2vh;
  line-height: 2vh;
  cursor: pointer;
}

footer .footer-right {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  flex-wrap: wrap;
  height: 100%;
  width: 50%;
  min-width: 450px;
}
footer .footer-right-top {
  width: 100%;
  height: 30%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 2%;
}

footer .footer-right-top a {
  display: flex;
  overflow: hidden;
  width: 2.4vw;
  height: 2.4vw;
  margin-left: 10px;
}
footer .footer-right-top a img {
  display: block;
  width: 100%;
}

footer .footer-right-top a:nth-child(6) {
  width: 3vw;
  height: 3vw;
}

footer .footer-right-bottom {
  width: 100%;
  height: 14%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 0.7vw;
  color: rgb(115 120 125 / 60%);
  /* padding-right: 7%; */
}

footer .footer-right-email {
  width: 100%;
  height: 18%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

footer .footer-right-email img {
  height: 0.7vw;
  margin-right: 0.5vw;
}

footer .footer-right-email a {
  font-size: 0.7vw;
  color: #ffffff;
  text-decoration: none;
  /* padding-right: 4%; */
  line-height: 0.7vw;
  border-bottom: 0.05vh solid #FFFFFF;
}

.footer-right-top-text {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.7vw;
  color: rgb(115 120 125 / 60%);
  padding-left: 39%;
}

.copyright {
  font-size: 1.25rem;
  color: #787e85;
}

/* footer li span {
  color: #fff;
} */

@media (max-width: 600px) {
  footer {
    height: 150px;  
    padding: 0;
    box-sizing: content-box;
  }
}
