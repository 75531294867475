/* @import '~animate.css/source/_vars.css'; */
@import "~animate.css/source/_base.css";
@import "~animate.css/source/fading_entrances/fadeIn.css";
@import "~animate.css/source/fading_entrances/fadeInLeft.css";
@import "~animate.css/source/fading_entrances/fadeInRight.css";
@import "~animate.css/source/fading_entrances/fadeInDown.css";
@import "~animate.css/source/fading_entrances/fadeInUp.css";

:root {
  --animate-duration: 0.8s;
  --animate-delay: 0.8s;
  --animate-repeat: 1;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.animated {
  opacity: 0;
}

.animated.littleslow {
  animation-duration: calc(var(--animate-duration) * 2);
}

.animated.delay--5s {
  animation-delay: calc(var(--animate-delay) / 2);
}
.animated.delay--15s {
  animation-delay: calc(var(--animate-delay) * 1.5);
}

.App {
  position: relative;
  overflow-x: hidden;
  transition-property: transform;
  transition-duration: 1000ms;
}

.App::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.03;
  z-index: -1;
}

section {
  position: relative;
  display: flow-root;
  width: 100%;
}

.home-swiper {
  height: 100vh;
}

.section-title-anchor {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  transform: translateY(calc(-75px - 1%));
  z-index: -1;
}

.display-block {
  display: block;
}

.tiny-loading {
  position: fixed;
  background-color: #18212C;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  height: 100%;
  width: 100%;
  z-index: 102;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.loading-box {
  position: relative;
  height: 250px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-box .loading-avatar {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-left: -20px;
}

.loading-rotate-box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 0.5px solid #fff;
  border-radius: 50%;
  position: relative;
  animation: spin 2s infinite cubic-bezier(0.65, 0.05, 0.36, 1);
}

.loading-box .loading-star {
  position: absolute;
  top: -7.5px;
  transform: translate(-50%, 0);
  left: 50%;
  width: 15px;
  height: 15px;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(1turn);
  }
}