.partner-content {
  display: flex;
  height: 100%;
  width: 100%;
  gap: 120px;
  align-items: center;
  justify-content: center;
  padding-top: 13vh;
}

.sexangle-box {
  display: flex;
  width: 83%;
  height: 56%;
  align-items: center;
  justify-content: center;
}

.sexangle-box-animate {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sexangle-box-center {
  display: flex;
  flex-direction: column;
}

.sexangle {
  /* width: 13.94rem; */
  height: 9.5rem;
  position: relative;
}

.sexangle > img {
  height: 100%;
  z-index: -1;
  display: block;
}

.sexangle > span {
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%);
  font-size: 1.5rem;
  color: #fec767;
  font-family: "Arial Black";
  display: flex;
  align-items: center;
  justify-content: center;
}

.sexangle > a {
  position: absolute;
  width: 100%;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.sexangle > span > img {
  /* width: 100%; */
  max-width: 8rem;
  max-height: 8rem;
  display: block;
}

.sexangle > a > img {
  /* width: 100%; */
  max-width: 10rem;
  max-height: 10rem;
  display: block;
}

.sexangle-box-left1 {
  position: relative;
  left: calc(2.6875rem * 1);
}

.sexangle-box-left2 {
  position: relative;
  left: calc(2.6875rem * 2);
}

.sexangle-box-left3 {
  position: relative;
  left: calc(2.6875rem * 3);
}

.sexangle-box-left4 {
  position: relative;
  left: calc(2.6875rem * 4);
}

.sexangle-box-left5 {
  position: relative;
  left: calc(2.6875rem * 5);
}

.sexangle-box-right1 {
  position: relative;
  right: calc(2.6875rem * 1);
}

.sexangle-box-right2 {
  position: relative;
  right: calc(2.6875rem * 2);
}

.sexangle-box-right3 {
  position: relative;
  right: calc(2.6875rem * 3);
}

.sexangle-box-right4 {
  position: relative;
  right: calc(2.6875rem * 4);
}

.sexangle-box-right5 {
  position: relative;
  right: calc(2.6875rem * 5);
}
