@media (max-width: 600px) {
  .loading-box {
    height: 150px;
    width: 150px;
  }

  .loading-box .loading-avatar {
    height: 130px;
    margin-left: -12px;
  }

  .mobile-header {
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 101;
    padding: 10px 0;
    transition: background-color 0.5s;
  }

  .show-header-bg {
    animation: BgFadeIn 0.5s ease-in forwards;
  }

  .hide-header-bg {
    animation: BgFadeOut 0.5s ease-out forwards;
  }

  .mobile-header-logo {
    width: 108px;
    height: auto;
    margin-left: 10px;
    transition: opacity 0.5s;
  }

  .show-mobile-header-logo {
    opacity: 1;
  }

  .hidden-mobile-header-logo {
    opacity: 0;
  }

  .mobile-menu {
    display: flex;
    flex-direction: column;
    margin-right: 3px;
    padding: 12px 15px;
    height: 50px;
    box-sizing: border-box;
    border-left: 1px solid rgba(255, 255, 255, 1);
    transition: all 0.3s;
  }

  .menu-expand {
    border-left: 1px solid rgba(255, 255, 255, 0);
  }

  .menu-close {
    border-left: 1px solid rgba(255, 255, 255, 1);
  }

  .mobile-menu > span {
    width: 28px;
    height: 6px;
    background-color: #fff;
    transition: all 0.3s;
  }
  .mobile-menu > span + span {
    margin-top: 4px;
  }

  .menu-expand > span:nth-child(1) {
    animation: first-span-close 0.5s ease-in-out forwards;
  }

  .menu-expand > span:nth-child(2) {
    animation: second-span-close 0.5s ease-in-out forwards;
  }

  .menu-expand > span:nth-child(3) {
    animation: third-span-close 0.5s ease-in-out forwards;
  }

  .menu-close > span:nth-child(1) {
    animation: first-span-on 0.5s ease-in-out forwards;
  }

  .menu-close > span:nth-child(2) {
    animation: second-span-on 0.5s ease-in-out forwards;
  }

  .menu-close > span:nth-child(3) {
    animation: third-span-on 0.5s ease-in-out forwards;
  }

  @keyframes first-span-on {
    0% {
      transform: translate(0, 7px) rotate(-45deg);
      height: 3px;
      border-radius: 3px;
    }

    30% {
      transform: translate(0, 7px) rotate(0deg);
      height: 3px;
      border-radius: 3px;
    }

    100% {
      transform: translate(0, 0) rotate(0deg);
      height: 6px;
      border-radius: 0px;
    }
  }

  @keyframes first-span-close {
    0% {
      transform: translate(0, 0);
      height: 6px;
    }

    30% {
      transform: translate(0, 0);
      height: 3px;
      border-radius: 3px;
    }

    100% {
      transform: translate(0, 7px) rotate(-45deg);
      height: 3px;
      border-radius: 3px;
    }
  }

  @keyframes second-span-on {
    0% {
      opacity: 0;
      height: 3px;
      border-radius: 3px;
    }

    50% {
      opacity: 0;
      height: 3px;
      border-radius: 3px;
    }

    100% {
      opacity: 1;
      height: 6px;
      border-radius: 0;
    }
  }

  @keyframes second-span-close {
    0% {
      opacity: 1;
      height: 6px;
    }

    30% {
      height: 3px;
      border-radius: 3px;
    }

    100% {
      opacity: 0;
      height: 3px;
      border-radius: 3px;
    }
  }

  @keyframes third-span-on {
    0% {
      transform: translate(0, -7px) rotate(45deg);
      height: 3px;
      border-radius: 3px;
    }

    30% {
      transform: translate(0, -7px) rotate(0deg);
      height: 3px;
      border-radius: 3px;
    }

    100% {
      transform: translate(0, 0) rotate(0deg);
      height: 6px;
      border-radius: 0;
    }
  }

  @keyframes third-span-close {
    0% {
      transform: translate(0, 0);
      height: 6px;
    }

    30% {
      transform: translate(0, 0);
      height: 3px;
      border-radius: 3px;
    }

    100% {
      transform: translate(0, -7px) rotate(45deg);
      height: 3px;
      border-radius: 3px;
    }
  }

  .mobile-nav {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(27, 40, 54, 0.9);
    z-index: 100;
  }

  .show-nav {
    animation: navFadeIn 0.5s ease-in forwards;
  }

  .hide-nav {
    animation: navFadeOut 0.5s ease-out forwards;
  }

  @keyframes BgFadeIn {
    0% {
      background-color: rgba(21, 39, 38, 0);
    }

    100% {
      background-color: rgba(21, 39, 38, 1);
    }
  }

  @keyframes BgFadeOut {
    0% {
      background-color: rgba(21, 39, 38, 1);
    }

    100% {
      background-color: rgba(21, 39, 38, 0);
    }
  }

  @keyframes navFadeIn {
    0% {
      opacity: 0;
      background-color: rgba(27, 40, 54, 0);
    }

    100% {
      opacity: 1;
      background-color: rgba(27, 40, 54, 0.9);
    }
  }

  @keyframes navFadeOut {
    0% {
      opacity: 1;
      background-color: rgba(27, 40, 54, 0.9);
    }

    100% {
      opacity: 0;
      background-color: rgba(27, 40, 54, 0);
    }
  }

  .launch-button {
    font-size: 1.1rem;
  }

  .mobile-nav-items {
    margin-top: 120px;
    margin-left: 10px;
    color: #fff;
    font-size: 1.67rem;
    font-weight: bold;
  }

  .mobile-nav-items li {
    list-style-image: none;
    list-style-position: outside;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .mobile-nav-items li + li {
    margin-top: 25px;
  }

  .mobile-nav-items .bounceIn {
    opacity: 0;
    animation: bounceIn 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }

  @keyframes bounceIn {
    0% {
      transform: translateY(-120px);
      opacity: 0;
    }

    100% {
      transform: translate(0);
      opacity: 1;
    }
  }

  .mobile-nav-items li img {
    width: 17.5px;
    height: 17.5px;
  }

  .mobile-nav-footer {
    position: absolute;
    bottom: 55px;
    text-align: center;
  color: rgb(115 120 125 / 60%);
    width: 100%;
    font-family: "Footer";
  }

  .mobile-footer {
    width: 100%;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .mobile-footer > .mobile-nav-footer {
    position: relative;
    bottom: 0;
    /* top: 10px; */
  }

  .mobile-nav-media {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: auto 50px;
  }

  .mobile-nav-media img {
    width: 30px;
    height: 30px;
  }

  .mobile-nav-media a:nth-child(6) img {
    width: 38px;
    height: 38px;
  }

  .mobile-nav-email {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .mobile-nav-email img {
    height: 1rem;
    margin-right: 0.5rem;
  }

  .mobile-nav-email a {
    font-size: 1rem;
    color: #ffffff;
    text-decoration: none;
    /* padding-right: 4%; */
    line-height: 1rem;
    border-bottom: 0.05vh solid #FFFFFF;
  }

  .mobile-nav-tip {
    margin-bottom: 10px;
    line-height: 20px;
  }

  .mobile-nav-slogan {
    /* margin-top: 10px; */
    line-height: 20px;
  }

  .mobile-home-shadow {
    z-index: 11;
    position: absolute;
    bottom: -40%;
    height: 100%;
  }

  .normal-section {
    background: linear-gradient(to bottom, #111419, #0e3045);
  }

  .roadmap-section {
    height: 100%;
    padding: 100px 0 50px;
    overflow-y: scroll;
    box-sizing: border-box;
  }

  .home-circle {
    /* position: fixed;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    bottom: -35%;
    animation: mobile-spin 500s infinite; */
    position: static;
    margin-top: 72vh;
    height: 100%;
    animation: spin 500s infinite;
  }

  .character-anime {
    right: auto;
  }

  @keyframes mobile-spin {
    0% {
      transform: translate3d(-50%, 0, 0) rotate(0deg);
    }

    100% {
      transform: translate3d(-50%, 0, 0) rotate(360deg);
    }
  }

  .brand {
    display: none;
  }

  .mobile-brand-logo {
    position: absolute;
    left: 50%;
    top: 7%;
    transform: translate3d(-50%, 0, 0);
    width: 80vw;
  }

  .mobile-launch-anchor {
    z-index: 12;
    position: absolute;
    bottom: 14%;
  }

  .video-section {
    background-position: 78%;
  }

  .introduction-content {
    width: 87vw;
    margin-top: 30vh;
    height: 100%;
    justify-content: flex-start;
  }

  .description, .kingdom-content-desc, .game-desc {
    font-size: 1.1rem;
    line-height: 1.6;
  }

  .section-title {
    top: 15%;
    font-size: 1.67rem;
  }

  .play-cards {
    padding-top: 9.5vh;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .play-cards .section-title {
    top: 11%;
  }

  .play-cards .buy-button {
    width: 130px;
    height: 37.5px;
    font-size: 1.1rem;
  }

  .cards-container {
    margin: 0 auto;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    max-height: 100%;
    width: 100vw;
  }

  .game-content {
    padding-top: 16vh;
  }

  .game-content .section-title {
    top: 10%;
  }

  .section-title > img {
    height: 16px;
    width: 16px;
  }

  .kingdom-carousel,
  .farm-carousel,
  .dungeons-carousel {
    height: 31.4254vh;
    width: 100%;
    position: relative;
    transform: translate(0, 0);
  }

  .kingdom-carousel img {
    height: 90%;
    left: 46%;
  }

  .game-content-section {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .game-content-section > div:nth-child(3) {
    position: static;
    width: 100%;
  }

  .game-content-section::after {
    background-image: none;
  }

  .kingdom-bg-role {
    top: auto;
    right: -35%;
    left: auto;
    height: auto;
    bottom: -6%;
    width: 80%;
    filter: opacity(0.7);
    z-index: -1;
  }

  .farm-bg-role {
    right: -31%;
  }

  .dungeons-bg-role {
    right: -22%;
  }

  .kingdom-content {
    height: auto;
    width: 100%;
  }

  .kingdom-content-title {
    font-size: 1.25rem;
  }

  .kingdom-content-right-section .game-logo {
    margin-top: 0; 
    width: 112px;
  }

  .kingdom-content-desc {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    width: 85vw;
    white-space: normal;
  }

  .kingdom-content-desc li {
    margin-top: 0;
  }

  .game-section .mobile-launch-button {
    width: 130px;
    height: 39.375px;
    font-size: 1rem;
  }

  .kingdom-carousel-poker {
    width: 80.841vw;
    margin: 0 auto;
    top: 10%;
    left: 8.5%;
  }

  .dungeons-carousel {
    display: flex;
    justify-content: center;
  }

  .dungeons-carousel img {
    height: 80%;
    left: 50%;
    top: 50%;
  }

  .mobile-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .navigation-arrow-left,
  .navigation-arrow-right {
    width: 26px;
    height: 36px;
  }

  .navigation-arrow-left {
    animation: sloshLeft 0.6s linear infinite alternate;
  }

  .navigation-arrow-right {
    transform: rotate(180deg);
    animation: sloshRight 0.6s linear infinite alternate;
  }

  @keyframes sloshLeft {
    0% {
      transform: translateX(0);
    }

    20% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-50%);
    }
  }

  @keyframes sloshRight {
    0% {
      transform: rotate(180deg) translateX(0);
    }

    20% {
      transform: rotate(180deg) translateX(0);
    }

    100% {
      transform: rotate(180deg) translateX(-50%);
    }
  }

  .token-section {
    background-image: url(../images/mobile-token-bg.png);
    background-position: bottom;
  }

  #tiny-world-universe {
    height: 50%;
  }

  .earth #tiny-kingdom.orbit {
    margin-left: -29em;
  }

  .game-desc {
    width: 80%;
    margin: -10vh auto 0;
  }

  .token-desc-items {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  .token-desc-item {
    flex-direction: row;
    align-items: center;
    min-width: 180px;
    gap: 0.5rem;
  }
  
  .token-desc-item img {
    width: 50px;
  }

  .token-desc-item span {
    text-align: left;
    font-size: 1rem;
    font-family: 'Footer';
    line-height: 1.4;
  }

  .roadmap-section {
    position: relative;
  }

  .roadmap-section::after {
    content: "ROADMAP";
    position: absolute;
    font-family: "Arial Black", "Arial Bold";
    -webkit-text-stroke: #4d3510 2px;
    color: rgba(0, 0, 0, 0);
    z-index: 1;
    top: 10%;
    right: 0%;
    bottom: auto;
    writing-mode: tb;
    font-size: 7rem;
  }

  .mobile-signpost-icon {
    width: 52px;
    height: 52px;
    background-color: #272727;
    border-radius: 50%;
    border: 2px solid #707070;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .mobile-signpost-icon img {
    width: 39.5px;
    height: auto;
  }

  .mobile-signpost {
    display: flex;
    padding-left: calc(10vw - 24.5px);
    position: relative;
  }

  .mobile-signpost::after {
    position: absolute;
    left: 10vw;
    top: -15%;
    content: "";
    height: 115%;
    width: 3px;
    background-color: #707070;
    /* z-index: -1; */
  }

  .mobile-signpost {
    margin-top: 1rem;
  }

  .signpost-content-item {
    margin-top: 5px;
    line-height: 1.5;
  }

  .mobile-signpost-content .signpost-date {
    padding-left: 10px;
    font-size: 1.25rem;
  }

  .mobile-signpost-content .signpost-content-item li {
    font-size: 1.125rem;
    list-style: none;
    position: relative;
  }

  .signpost-content-item li::marker {
    content: "";
  }

  .signpost-content-item .checked::marker {
    content: "";
  }

  .signpost-content-item li::before {
    content: "";
    width: 13px;
    height: 100%;
    background-image: url(../images/roadmap/item-icon.png);
    background-size: 13px 9px;
    background-repeat: no-repeat;
    position: absolute;
    left: -10px;
    top: 4px;
  }

  .signpost-content-item .checked::before {
    content: "";
    background-image: url(../images/roadmap/check.png);
    width: 13px;
    height: 100%;
    background-size: 13px 13px;
    background-repeat: no-repeat;
    position: absolute;
    left: -10px;
    top: 2px;
  }

  .partner-section {
    flex-direction: column;
    overflow-y: scroll;
    height: 100%;
  }

  .partner-section .section-title {
    position: static;
    transform: translate(0, 0);
  }

  .mobile-partners-content {
    height: 100%;
    padding-top: 13vh;
  }

  .mobile-investors {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mobile-partners {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mobile-investor-matrix,
  .mobile-partner-matrix {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 9px 7px;
  }

  .mobile-investor-item,
  .mobile-partner-item {
    width: 76.42px;
    height: 64.89px;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgb(0, 0, 0);
    box-sizing: border-box;
  }

  .mobile-investor-item {
    background-color: #00193c;
    border: 1.5px solid #1c5e9f;
  }

  .mobile-partner-item {
    background-color: #002e3c;
    border: 1.5px solid #107585;
  }

  .mobile-investor-item > img,
  .mobile-partner-item > img {
    max-width: 76.42px;
    max-height: 64.89px;
  }
}
