html {
  font-size: 16px;
}

@media (max-width: 600px) {
  html {
    font-size: 12px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fffff8;
  overflow-x: hidden;

  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-y: auto;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  outline: 0 none;
  border: 0 none;
  cursor: pointer;
  color: #fff;
  background-color: transparent;
}

.center-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.text-center {
  text-align: center;
}

.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.main-content {
  max-width: 1900px;
}
