.affix-nav {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0;
  width: 68px;
  background-color: rgba(0, 0, 0, 0.5);
  border-top-left-radius: 31px;
  border-bottom-left-radius: 31px;
  z-index: 99;
  transition: background-color 1s;
}

.affix-nav img {
  width: 2.5rem;
  max-width: 40px;
  transition: all .3s;
}

@media (max-width: 1400px) {
  .affix-nav {
    width: 60px;
  }

  .affix-nav img {
    width: 36px;
  }
}

@media (max-width: 1200px) {
  .affix-nav {
    width: 56px;
    border-top-left-radius: 28px;
    border-bottom-left-radius: 28px;
  }

  .affix-nav img {
    width: 32px;
  }
}

@media (max-width: 768px) {
  .affix-nav {
    width: 52px;
  }

  .affix-nav img {
    width: 28px;
  }
}

.affix-nav img:hover {
  transform: scale(1.3);
}

.swiper-container {
  height: 100vh;
}

.home-circle {
  position: absolute;
  /* right: -40vh; */
  right: -19%;
  height: 156%;
  bottom: -66%;
  animation: spin 500s infinite;
}

.feature-content {
  padding-top: 13vh;
  height: 100%;
  overflow: hidden;
}

.flip-card-content .content-bottom {
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: url(/src/images/link/card-boss.png) 0% 0% / cover; */
}

.flip-card-content .content-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: url(/src/images/link/card-boss-filter.png) 0% 0% / cover; */
  /* mask-image: url(/src/images/link/mask.png); */
  mask-size: 4900% 100%;
}

.draw-content {
  animation: inMask 1.5s forwards;
  animation-timing-function: steps(48);
}

.feature-cards > div:nth-child(1) .draw-content {
  animation-delay: 1s;
}

.feature-cards > div:nth-child(2) .draw-content {
  animation-delay: 1.5s;
}

.feature-cards > div:nth-child(3) .draw-content {
  animation-delay: 2s;
}

.fadeMask-enter-active,
.fadeMask-leave-active {
  transition: all 1s;
}

section {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.home-section {
  height: 100%;
  width: 100%;
  position: relative;
}

.character-anime {
  position: absolute;
  bottom: 0;
  right: 0;
}
.character-anime > canvas {
  display: block;
}

.scale05 > canvas {
  transform: scale(0.5);
  transform-origin: 50% 100%;
}

.home-bg {
  width: 120%;
  height: 120%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../images/section-home/home-bg.png);
  /* transition: background-position; */
}

.brand {
  position: absolute;
  left: 3%;
  height: 50%;
  top: 16%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 11;
}

.brand .brand-logo {
  width: 50vw;
  height: auto;
}

.brand a {
  margin-top: -10%;
  position: relative;
  height: 8vh;
}

.brand a button {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  height: 8vh;
  width: 26.4vh;
  padding-top: 0.84vh;
}

.brand a img {
  opacity: 0;
  height: 100%;
}

.launch-button {
  height: 6vh;
  width: 19.8vh;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 600;
  padding: 4% 0 0 0;
  background-image: url(../images/link/button/launch-normal.png);
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 0.2s, box-shadow 0.2s, background-image 0.2s;
  text-shadow: 0 2px 5px #000000aa;
  font-size: 1.2vw;
}

.launch-button:hover {
  background-image: url(../images/link/button/launch-hover.png);
  transform: translateY(-1px);
  text-shadow: none;
}

.launch-button:active {
  /* color: #ffd576; */
  background-image: url(../images/link/button/launch-active.png);
  transform: translateY(2px);
}

.video-section {
  background: url(../images/section-video/bg1.png) no-repeat 50%;
  height: 100%;
  width: 100%;
  background-size: cover;
}

.home-circle {
  position: absolute;
  right: -40vh;
  height: 157%;
  bottom: -66%;
  animation: spin 500s infinite;
}

.home-shadow {
  position: absolute;
  height: 100%;
  width: 100%;
}

.home-character {
  position: absolute;
  bottom: -35%;
  height: 125%;
  right: 8%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.section-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  /* font-size: 2.375rem; */
  font-size: 2vw;
  color: #fec767;
  position: absolute;
  top: 14%;
  left: 50%;
  transform: translate(-50%, 0);
}

.section-title > img {
  height: 1.5vw;
  width: 1.5vw;
}

.description {
  color: #fff;
  /* font-size: 1.25rem; */
  font-size: 1vw;
  width: 92%;
  margin-top: 1.5rem;
}

.description,
.kingdom-content-desc,
.game-desc {
  line-height: 1.5;
}

.introduction-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 42vw;
}

.introduction-content .section-title {
  position: static;
  transform: translate(0, 0);
  margin: 1rem 0;
}

.introduction-content .video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}

.introduction-content .video-container img {
  width: 100%;
}

.introduction-content .video-container iframe {
  position: absolute;
  width: 92%;
  height: 85%;
}

.introduction-content .video-container video {
  position: absolute;
  width: 92%;
  height: 85%;
}

video:focus,
video:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.normal-section {
  /* background: linear-gradient(to bottom, #18212c, #121f2b); */
  background: linear-gradient(to top, #18212c, #121f2b);
  height: 100%;
}

.draw-content {
  animation: inMask 1.5s forwards;
  animation-timing-function: steps(48);
}

.feature-cards > div:nth-child(1) .draw-content {
  animation-delay: 1s;
}

.feature-cards > div:nth-child(2) .draw-content {
  animation-delay: 1.5s;
}

.feature-cards > div:nth-child(3) .draw-content {
  animation-delay: 2s;
}

@keyframes flip {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(180deg);
  }
}

@keyframes inMask {
  0% {
    -webkit-mask-position: 0 50%;
    mask-position: 0 50%;
  }
  100% {
    -webkit-mask-position: 100% 50%;
    mask-position: 100% 50%;
  }
}

.fadeMask-enter-active,
.fadeMask-leave-active {
  transition: all 1s;
}

.fadeMask-enter,
.fadeMask-leave-active {
  opacity: 0;
}

.play-section {
  flex-direction: column;
}

.play-cards {
  display: flex;
  justify-content: center;
  height: 100%;
  padding-top: 13vh;
}

.play-swiper {
  width: 200vw;
  height: 170%;
  top: -30%;
}

.game-section {
  flex-direction: column;
}

.game-content {
  height: 100%;
  padding-top: 24vh;
}

.game-swiper {
  width: 100vw;
  height: 100%;
}

.game-content-section {
  display: flex;
}

.game-content-section > div:nth-child(3) {
  position: relative;
  width: 36%;
}

.game-content-kingdom {
  height: 60vh;
  overflow: hidden;
  position: relative;
}

.game-content-section::after {
  content: "";
  height: 100%;
  width: 100%;
  background-size: 100% 90%;
  background-repeat: no-repeat;
  background-position: left 0 bottom 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: -1;
}

.game-content-kingdom::after {
  background-image: url(../images/link/kingdom-bg1.png);
}

.game-content-farm {
  height: 60vh;
  overflow: hidden;
  position: relative;
}

.game-content-farm::after {
  background-image: url(../images/link/kingdom-bg1-1.png);
}

.game-content-dungeons {
  height: 60vh;
  overflow: hidden;
  position: relative;
}

.game-content-dungeons::after {
  background-image: url(../images/link/kingdom-bg1-2.png);
}

.kingdom-bg-role {
  position: absolute;
  top: 8%;
  left: -9%;
  height: 100%;
  width: auto;
}

.farm-bg-role {
  /* top: 0%; */
}

.dungeons-bg-role {
  top: 6%;
  left: -12%;
}

.kingdom-content {
  width: 28%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.game-carousel {
  width: 36%;
}

.kingdom-carousel {
  height: 100%;
  position: relative;
  transform: translateY(5%);
}

.kingdom-carousel-poker {
  position: absolute;
  width: 75%;
  top: 17vh;
  left: 19%;
}

.kingdom-carousel-shadow {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.game-carousel-dungeon-pic1 {
  transform: rotate(340deg) translate(8vh, 4vw);
  height: 86%;
  z-index: -2;
  width: auto;
}

.game-carousel-dungeon-pic2 {
  z-index: 2;
  height: 90%;
  width: auto;
  position: absolute;
}

.kingdom-carousel > div {
  position: absolute;
}

.kingdom-carousel img {
  height: 66%;
  position: absolute;
  top: 50%;
  left: 53%;
  transform: translate(-50%, -50%);
  transition: transform 0.8s, filter 0.8s;
}

.active-game-swiper .kingdom-carousel img {
  filter: drop-shadow(-16px 4px 6px rgba(0, 0, 0, 0.5));
}

.active-game-swiper .kingdom-carousel img:nth-child(1) {
  transform: translate(-108%, -44%) rotate(-7deg) scale(0.95);
}

.active-game-swiper .kingdom-carousel img:nth-child(2) {
  transform: translate(-42%, -52%) rotate(2deg);
}

.active-game-swiper .kingdom-carousel img:nth-child(3) {
  transform: translate(22%, -51%) rotate(14deg) scale(0.98);
}

.farm-carousel {
  display: flex;
  align-items: center;
  position: relative;
}

.dungeons-carousel {
  height: 100%;
  transform: translateY(5%);
  position: relative;
}

.dungeons-carousel img {
  height: 73%;
  width: auto;
  position: absolute;
  left: 59%;
  top: 52%;
  transform: translate(-50%, -50%);
  transition: transform 0.8s, filter 0.8s;
  filter: drop-shadow(6px 7px 12px rgba(0, 0, 0, 0.5));
}

.dungeons-carousel .game-carousel-dungeon-pic1 {
  transform: translate(-82%, -50%) rotate(-15deg);
}

.dungeons-carousel .game-carousel-dungeon-pic2 {
  transform: translate(-10%, -56%) rotate(7deg);
}

.kingdom-content-title {
  font-family: "Arial Black", "Arial Bold";
  margin: 1rem 0;
}

.kingdom-content-desc {
  list-style-type: none;
  padding-left: 0;
  width: 100%;
  font-family: "Arial";
  font-size: 1vw;
  line-height: 1.5;
  margin: 2.4rem auto 2.4rem;
}

.dungeons-content-desc {
  margin-top: 2.4rem;
}

.kingdom-content-desc li {
  font-weight: normal;
}

.kingdom-content-right-section {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.kingdom-content-right-section .game-logo {
  width: 9.17vw;
  height: auto;
  /* margin-top: 2rem; */
}

.kingdom-content .launch-button {
  font-size: 0.75vw;
  height: 5vh;
  width: 16.5vh;
}

.kingdom-swiper .swiper-slide {
  width: 257px !important;
}

/* 
  token section style
 */
.token-section {
  flex-direction: column;
}

.token-content {
  width: 100%;
  height: 100%;
  padding-top: 13vh;
}

.game-desc {
  color: #fff;
  width: 41%;
  margin: -15vh auto 0;
  font-size: 1vw;
}

.token-desc-items {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2rem;
}

.token-desc-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.token-desc-item img {
  width: 3vw;
  filter: opacity(0.6);
}

.token-desc-item span {
  text-align: center;
  font-size: 0.75vw;
  font-family: "Footer";
  color: rgba(255, 255, 255, 0.6);
}

.game-desc ul {
  list-style-type: none;
  padding-inline-start: 0;
  margin-block-start: 0.5rem;
}

.game-desc ul > li {
  text-indent: -5px;
}
.game-desc ul > li:before {
  content: "-";
  text-indent: -5px;
  padding: 5px;
}

#tiny-world-universe {
  z-index: 1;
  overflow: hidden;
  width: 100%;
  height: 87%;
  background-position: center 40%;
  background-repeat: no-repeat;
  background-size: cover;
}

#galaxy {
  position: relative;
  width: 100%;
  height: 100%;
}

#tiny-system {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
}

.orbit {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transform-style: preserve-3d;
  animation-name: orbit;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.orbit .orbit {
  transform-style: preserve-3d;
  animation-name: suborbit;
}

.pos {
  position: absolute;
  width: 2em;
  height: 2em;
  margin-top: -1em;
  margin-left: -1em;
  transform-style: preserve-3d;
  animation-name: invert;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

#token,
.tiny-planet,
#tiny-kingdom .moon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.4em;
  height: 1.4em;
  margin-top: -0.7em;
  margin-left: -0.7em;
  border-radius: 50%;
  transform-style: preserve-3d;
}

.tiny-kingdom {
  top: 0;
}

#token {
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 0 60px rgba(255, 160, 60, 0.4);
}

#tiny-kingdom .pos,
#tiny-kingdom .tiny-kingdom,
#tiny-kingdom.orbit {
  animation-duration: 12.00021s;
}

#tiny-kingdom .orbit .pos,
#tiny-kingdom .orbit {
  animation-duration: 0.89764s;
}

@keyframes orbit {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(-360deg);
  }
}

@keyframes suborbit {
  0% {
    transform: rotateX(90deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(90deg) rotateZ(-360deg);
  }
}

@keyframes invert {
  0% {
    transform: rotateX(-90deg) rotateY(360deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
  }
}

@keyframes shadow-kingdom {
  0% {
    box-shadow: inset -24px 0 7.5px rgba(0, 0, 0, 0.5);
    /* RIGHT */
  }

  25% {
    box-shadow: inset 0 4.5px 3px rgba(0, 0, 0, 0.5);
    /* TOP */
  }

  50% {
    box-shadow: inset 24px 0 7.5px rgba(0, 0, 0, 0.5);
    /* LEFT */
  }

  75% {
    box-shadow: inset 142px -45px 60.5px rgba(0, 0, 0, 0.5);
    /* BOTTOM */
  }

  75.01% {
    box-shadow: inset -142px -45px 60.5px rgba(0, 0, 0, 0.5);
    /* BOTTOM */
  }

  100% {
    box-shadow: inset -24px 0 7.5px rgba(0, 0, 0, 0.5);
    /* RIGHT */
  }
}

@keyframes shadow-dao {
  0% {
    box-shadow: inset 0 4.5px 3px rgba(0, 0, 0, 0.5);
    /* TOP */
  }

  25% {
    box-shadow: inset 24px 0 7.5px rgba(0, 0, 0, 0.5);
    /* LEFT */
  }

  50% {
    box-shadow: inset 142px -45px 60.5px rgba(0, 0, 0, 0.5);
    /* BOTTOM */
  }

  50.01% {
    box-shadow: inset -142px -45px 60.5px rgba(0, 0, 0, 0.5);
    /* BOTTOM */
  }

  75% {
    box-shadow: inset -24px 0 7.5px rgba(0, 0, 0, 0.5);
    /* RIGHT */
  }

  100% {
    box-shadow: inset 0 4.5px 3px rgba(0, 0, 0, 0.5);
    /* TOP */
  }
}

@keyframes shadow-farm {
  0% {
    box-shadow: inset 24px 0 7.5px rgba(0, 0, 0, 0.5);
    /* LEFT */
  }

  25% {
    box-shadow: inset 142px -45px 60.5px rgba(0, 0, 0, 0.5);
    /* BOTTOM */
  }

  25.01% {
    box-shadow: inset -142px -45px 60.5px rgba(0, 0, 0, 0.5);
    /* BOTTOM */
  }

  50% {
    box-shadow: inset -24px 0 7.5px rgba(0, 0, 0, 0.5);
    /* RIGHT */
  }

  75% {
    box-shadow: inset 0 4.5px 3px rgba(0, 0, 0, 0.5);
    /* TOP */
  }

  100% {
    box-shadow: inset 24px 0 7.5px rgba(0, 0, 0, 0.5);
    /* LEFT */
  }
}

@keyframes shadow-rune {
  0% {
    box-shadow: inset 142px -45px 60.5px rgba(0, 0, 0, 0.5);
    /* BOTTOM */
  }

  0.01% {
    box-shadow: inset -142px -45px 60.5px rgba(0, 0, 0, 0.5);
    /* BOTTOM */
  }

  25% {
    box-shadow: inset -24px 0 7.5px rgba(0, 0, 0, 0.5);
    /* RIGHT */
  }

  50% {
    box-shadow: inset 0 4.5px 3px rgba(0, 0, 0, 0.5);
    /* TOP */
  }

  75% {
    box-shadow: inset 24px 0 7.5px rgba(0, 0, 0, 0.5);
    /* LEFT */
  }

  100% {
    box-shadow: inset 142px -45px 60.5px rgba(0, 0, 0, 0.5);
    /* BOTTOM */
  }
}

#tiny-kingdom .kingdom-pos {
  left: 100%;
  top: 50%;
}

#tiny-kingdom .dao-pos {
  left: 50%;
  top: 0;
}

#tiny-kingdom .farm-pos {
  left: 0;
  top: 50%;
}

#tiny-kingdom .rune-pos {
  left: 50%;
  top: 100%;
}

.earth #tiny-kingdom .infos {
  display: block;
  opacity: 1;
  transform: rotateX(0deg);
}

.earth #tiny-kingdom.orbit {
  border: 0.75rem solid rgba(254, 199, 103, 0.2);
  width: 57em;
  height: 57em;
  margin-top: -28.5em;
  margin-left: -28.5em;
}

/* ------------------------------------ transitions ------------------------------------ */
.pos {
  transition-property: top, left;
}

#tiny-system,
.orbit,
.tiny-kingdom,
.satelite,
.ring {
  transition-property: width, height, top, left, margin-left, margin-top,
    webkit-transform;
}

#tiny-system,
#token,
.orbit,
.pos,
.tiny-kingdom,
.satelite,
.ring,
.infos,
.icon {
  transition-duration: 0.8s;
  transition-timing-function: ease-in-out;
}

#tiny-system,
#token,
.tiny-kingdom,
.satelite,
.ring {
  transition-delay: 0s;
}

.pos {
  transition-delay: 1s;
}

#token {
  font-size: 16rem;
  transform-style: preserve-3d;
  transform: rotateX(-90deg);
  background-image: url(../images/link/TINC.png);
  box-shadow: 0 0 0 rgba(255, 160, 60, 0);
}

#tiny-kingdom .tiny-planet {
  width: 7em;
  height: 7em;
  margin-left: -3.5em;
  margin-top: -3.5em;
  /* background-color: #202020; */
  animation-duration: 12.00021s;
  background-repeat: no-repeat;
  background-size: cover;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

#tiny-kingdom .tiny-kingdom {
  animation-name: shadow-kingdom;
  background-image: url(../images/link/planet-kingdom.png);
}

#tiny-kingdom .tiny-dao {
  animation-name: shadow-dao;
  background-image: url(../images/link/planet-dao.png);
}

#tiny-kingdom .tiny-farm {
  animation-name: shadow-farm;
  background-image: url(../images/link/planet-farm.png);
}

#tiny-kingdom .tiny-rune {
  animation-name: shadow-rune;
  background-image: url(../images/link/planet-rune.png);
}

#sun,
.orbit,
.pos,
.tiny-kingdom,
.satelite,
.ring {
  transition-duration: 4s;
}

/* 3D view */
#tiny-system {
  transform: rotateX(75deg);
}

.view-3D .tiny-kingdom,
.view-3D .moon {
  transform: rotateX(0deg);
}

.roadmap-section {
  font-family: "Arial";
}

.roadmap-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.roadmap-container-bg {
  position: absolute;
  z-index: 0;
  width: 83.5416%;
  height: 46.39%;
  left: 50%;
  transform: translate(-50%, 0);
}

.roadmap-container::after {
  content: "ROADMAP";
  position: absolute;
  right: 3.6%;
  bottom: 1%;
  font-family: "Arial Black", "Arial Bold";
  font-size: 10rem;
  -webkit-text-stroke: #4d3510 2px;
  color: rgba(0, 0, 0, 0);
  z-index: 0;
}

.roadmap-content {
  position: relative;
  height: 52.3%;
  width: 100%;
  z-index: 1;
}

.signpost {
  position: absolute;
  /* width: 170px;
  height: 100px; */
  width: 5vw;
  height: 8vh;
}

.signpost-picture {
  position: relative;
}

.signpost-picture img {
  position: absolute;
}

.signpost-base {
  /* width: 170px; */
  height: 7vh;
  transform: translate(-50%, -50%);
}

.signpost-castle {
  width: auto;
  height: 9vh;
  visibility: hidden;
}

.signpost-1 {
  top: 5%;
  right: 10%;
}

.signpost-2 {
  top: 5%;
  right: 25%;
}

.signpost-3 {
  top: 5%;
  right: 40%;
}

.signpost-4 {
  top: 29%;
  /* left: 40%; */
  left: 38%;
}

.signpost-5 {
  top: 75%;
  /* right: 32%; */
  right: 34%;
}

.signpost-6 {
  top: 95%;
  left: 48%;
}

.signpost-7 {
  top: 95%;
  left: 33%;
}

.signpost-8 {
  top: 95%;
  left: 18%;
}

.signpost-content {
  position: absolute;
  left: -50%;
  top: 50%;
  width: 15vw;
  visibility: hidden;
}

.signpost-date {
  font-family: "Arial Black", "Arial Bold";
  /* font-size: 1.25em; */
  font-size: 1vw;
  color: #f29601;
  padding-left: 0.2vw;
}

.signpost-content-item {
  color: #fff;
  margin-top: 0.75em;
  padding-inline-start: 20px;
  /* padding-left: 20px; */
}

.signpost-content.signpost-item-box-1 {
  width: 16vw;
  left: -430% !important;
  background: #2b323b;
  padding: 10%;
  position: relative;
}

.signpost-content.signpost-item-box-2 {
  /* width: 260px; */
  width: 15vw;
  /* left: -220% !important; */
  background: #2b323b;
  padding: 10%;
  position: relative;
}

.signpost-item-box-img-1 {
  width: 1vw;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(-50%, 50%);
}

.signpost-item-box-img-2 {
  width: 1vw;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
}

.signpost-4 .signpost-content {
  top: -100%;
  left: -160%;
}

.signpost-5 .signpost-content {
  top: -100%;
  left: 80%;
}

.signpost-content-item li {
  font-weight: normal;
  /* font-size: 1.125rem; */
  font-size: 1vw;
  line-height: 1.25;
  padding-left: 5px;
}

.signpost-content-item li::marker {
  content: url(../images/roadmap/item-icon.png);
}

/* .signpost-content-item li {
  list-style-image: url(../images/link/item-icon.png);
} */

.signpost-content-item .checked::marker {
  content: url(../images/roadmap/check.png);
}

/* .signpost-content-item .checked {
  list-style-image: url(../images/roadmap/check.png);
} */

.fadeInDownward {
  visibility: visible;
  animation: fadeInDownward 3s linear;
  animation-duration: 0.6s;
  animation-fill-mode: both;
}

.fadeInUpward {
  visibility: visible;
  animation: fadeInUpward 3s linear;
  animation-duration: 0.6s;
  animation-fill-mode: both;
}

.fadeInLeft {
  visibility: visible;
  animation: fadeInLeft 3s linear;
  animation-duration: 0.6s;
  animation-fill-mode: both;
}

.fadeInRight {
  visibility: visible;
  animation: fadeInRight 3s linear;
  animation-duration: 0.6s;
  animation-fill-mode: both;
}

.signpost-8 .fadeInDownward {
  animation-delay: 0.3s;
}

.signpost-7 .fadeInDownward {
  animation-delay: 0.5s;
}

.signpost-6 .fadeInDownward {
  animation-delay: 0.7s;
}

.signpost-5 .fadeInLeft {
  animation-delay: 0.9s;
}

.signpost-4 .fadeInRight {
  animation-delay: 1.1s;
}

.signpost-3 .fadeInDownward {
  animation-delay: 1.3s;
}

.signpost-2 .fadeInDownward {
  animation-delay: 1.5s;
}

.signpost-1 .fadeInDownward {
  animation-delay: 1.7s;
}

.castle-drop {
  visibility: visible;
  animation: castle-drop-down 5s;
  animation-duration: 0.6s;
  animation-fill-mode: both;
}

.signpost-8 .castle-drop {
  animation-delay: 0s;
}

.signpost-7 .castle-drop {
  animation-delay: 0.2s;
}

.signpost-6 .castle-drop {
  animation-delay: 0.4s;
}

.signpost-5 .castle-drop {
  animation-delay: 0.6s;
}

.signpost-4 .castle-drop {
  animation-delay: 0.8s;
}

.signpost-3 .castle-drop {
  animation-delay: 1s;
}

.signpost-2 .castle-drop {
  animation-delay: 1.2s;
}

.signpost-1 .castle-drop {
  animation-delay: 1.4s;
}

@keyframes fadeInUpward {
  0% {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInDownward {
  0% {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-50%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(50%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes castle-drop-down {
  0% {
    transform: translate3d(-57%, -130%, 0) rotate(-3deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  35% {
    transform: translate3d(-55%, -100%, 0) rotate(-10deg);
    animation-timing-function: ease-out;
    opacity: 1;
  }
  45% {
    transform: translate3d(-50%, -105%, 0) rotate(3deg);
    animation-timing-function: ease-in;
  }
  55% {
    transform: translate3d(-46%, -100%, 0) rotate(10deg);
    animation-timing-function: ease-in;
  }
  62% {
    transform: translate3d(-50%, -103%, 0) rotate(-1deg);
    animation-timing-function: ease-out;
  }
  69% {
    transform: translate3d(-53%, -100%, 0) rotate(-5deg);
    animation-timing-function: ease-in;
  }
  75% {
    transform: translate3d(-49%, -101%, 0) rotate(1deg);
    animation-timing-function: ease-in;
  }
  81% {
    transform: translate3d(-47%, -100%, 0) rotate(5deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translate3d(-50%, -100%, 0);
    animation-timing-function: ease-out;
  }
}
