/* header 页面 */
header {
  position: fixed;
  top: 6vh;
  z-index: 9;
  box-sizing: border-box;
  width: 100vw;
  font-size: 1.25rem;
  padding: 0 3vw;
  display: flex;
  justify-content: center;
  font-family: "Footer";
}

header a {
  position: absolute;
  right: 4%;
  top: 44%;
  transform: translateY(-50%);
  color: white;
  background-color: transparent;
  font-family: "Arial Black";
  text-shadow: 0 3px 5px #000000aa;
  text-decoration: none;
  transition: transform 1s;
}

header li {
  display: inline-flex;
  align-items: center;
}

header li img {
  width: 1vw;
  height: 1vw;
  margin-right: 3px;
}

header li span {
  margin: 0 auto;
  color: white;
  cursor: pointer;
  background-color: transparent;
  font-family: "Footer";
  text-shadow: 0 3px 5px #000000aa;
  transition: all 0.1s;
}

header li.active span, .mobile-nav li.active span {
  color: #ffd576;
}

header li:hover {
  text-shadow: 0 4px 5px #000000aa;
  transform: translateY(-1px) scale(1.1);
}

header li:active {
  text-shadow: 0 2px 5px #000000aa;
  transform: translateY(1px);
}

header li:active a{
  color: #ffd576;
}

nav {
  width: 60%;
  position: relative;
  min-width: 550px;
}

nav > ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 1vw;
  height: 6vh;
  border-radius: 4vh;
  gap: 1vw;
  margin: 0;
  padding: 0 2vw;
  width: 100%;
}

nav > ul > li {
  white-space: nowrap;
  /* font-size: 1vw; */
}

nav a {
  text-decoration: none;
}

header > * {
  box-sizing: border-box;
}


.header-logo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 4%;
  height: 10vh;
  transition: transform 1s;
}

.header-logo img {
  height: 100%;
}

.header-launch-button {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 600;
  background-image: url(../images/link/button/launch-normal.png);
  background-size: contain;
  background-repeat: no-repeat;
  text-shadow: 0 2px 5px #000000aa;
  transition: box-shadow 0.2s, background-image 0.2s;
  height: 6vh;
  width: 19.8vh;
  display: block;
  padding-top: 0.63vh;
  font-size: 0.9vw;
}

.header-launch-button:hover {
  background-image: url(../images/link/button/launch-hover.png);
  box-shadow: 0 0 1px none;
  transform: translateY(-1px);
}

.header-launch-button:active {
  background-image: url(../images/link/button/launch-active.png);
  box-shadow: 0 0 1px #00000033;
  transform: translateY(2px);
}

.show-nav {
  display: flex;
  align-items: center;
}

.header-icon-home {
  margin: 0 -2px;
  width: 27px;
  max-height: 27px;
  transition: transform 0.1s;
}
.header-icon-home:hover {
  transform: scale(1.1);
}
.header-icon-home:active {
  transform: scale(0.9);
}

.header-line {
  margin: 0 8px;
  color: #3f1705;
}

.header-launch-button span {
  position: absolute;
  left: 0;
  top: 5%;
  height: 100%;
  width: 100%;
  font-size: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-launch-button img {
  height: 100%;
  opacity: 0;
  z-index: -99;
}

.header-button-toggle {
  display: none;
}

.mobile-join-presale {
  position: relative;
}

.mobile-presale-rune {
  position: absolute;
  bottom: -6px;
  left: -4px;
}

.mobile-presale-button {
  width: 141px;
  height: 28px;
  border-radius: 28px;
  transition: transform 0.2s, box-shadow 0.2s, background-image 0.2s;
  box-shadow: 0 5px 1px #00000033;
  font-size: 0.96rem;
  padding-left: 30px;
  background-image: radial-gradient(farthest-side, #d06409, #ffce2a);
}
.mobile-join-presale:active {
  transform: translateY(2px);
  box-shadow: 0 0 1px #00000033;
}

.mobile-join-presale:active .mobile-presale-button {
  background-image: radial-gradient(farthest-side, #c10f07, #fc7613);
}

@media (max-width: 1000px) {
  .header-container {
    padding: 0;
  }
  .header-pc-content {
    padding: 0 20px;
  }
}

@media (max-width: 600px) {
  header {
    height: 60px;
  }
  .header-pc-content {
    padding-top: 5px;
  }
  .header-logo {
    height: 69px;
  }
  .header-button-play {
    width: 115px;
    height: 32px;
  }
}


@media (max-width: 600px) {
  .header-launch-button {
    display: none;
  }

  .show-nav {
    display: block;
  }
}